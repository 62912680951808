import React from "react";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import DeleteModal from './components/DeleteModal';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ResultErrors from './components/ResultErrors';
import Loading from './components/Loading';
import ResultQuery from './components/ResultQuery';
import { Link } from "react-router-dom";
import Pagination from './components/Pagination';
import { Navigate } from "react-router-dom";
import Button from './components/Button';
import Table from './components/Table';
import sprite from './media/icons.svg';
import rewards_img from './media/rewards.png';
import { getAuth } from "firebase/auth";
class Rewards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      successRewardSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("successReward") ? JSON.parse(localStorage.getItem("successReward")) : null) : '',
      totalPages: null,
      currentPage: 0,
      rewards: [],
      errors: null,
      isLoaded: false,
      modalDeleteRewards: false,
      idDeleteRewards: null,
      removeIndex: null,
      indexRowRewards: null,
      navPath: ''
    }
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  /* Handler for close success labels */
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successRewardSubmit: null
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      localStorage.removeItem('successReward');
    }
  }

  /* Handler for close errors labels */
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false,
        modalDeleteRewards: false,
        removeIndex: null,
        idDeleteRewards: null
      }));
    }, 500);
  }

  /* Pagination handler */
  handlerPaginationItems = (eventsItems, current) => {
    this.setState({
      rewards: eventsItems,
      currentPage: current
    });
  }

  /* Handler get data mount component */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 20,
          "orderField": "creationDate",
          "direction": 'DESC'
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.message,
              error: true
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              rewards: data.data,
              totalPages: data.totalPages
            });
          })
        }
      })
    })
  }

  /* Handler for change status */
  handlerEnabled(id, index, event){
    let that = this;
    let arr = that.state.rewards;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${id}/enable`, {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({"enable": arr[index].enabled ? false : true})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            arr[index] = data;
            that.setState({
              rewards: arr
            })
          })
        }
      })
    });
  }

  /* Ads modal delete handler */
  handlerDeleteRewardsModal(id, index, event){
    this.setState({
      modalDeleteRewards: true,
      removeIndex: index,
      idDeleteRewards: id
    });
  }

handlerDeleteRewards(id, event){
  let that = this;
  that.setState({
    loadingDeleteSumbit: true
  })
  getAuth().currentUser.getIdToken().then(function(idToken){
  const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
  });
  fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/${id}`, {
    method: 'DELETE',
    headers: myHeaders
  }).then(res => {
    if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
    } else {
      that.setState({
        indexRowRewards: that.state.removeIndex
      });
      fetch(`${that.props.queryLink}/api/v1/encouragement/rewards/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({"itemsPerPage": 20, "page": that.state.rewards.length < 2 ? 1 : that.state.currentPage})
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              error: true
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              indexRowRewards: null,
              removeIndex: null,
              rewards: data.data,
              totalPages: data.totalPages,
              loadingDeleteSumbit: false
            });
          });
          that.handleModalDisabled();
        }
      })
    }
  })
})
}

handlerPermalinkRewards(id, index, event){
  if(!event.target.closest('.control-wgt')) {
    this.setState(prevState => ({
      navPath: `/account/rewards/view/${id}`
    }));
  }
}

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('rewards-Title') + ' | ' + this.props.companyName;
    }
    if(this.state.successRewardSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultRewards = '';
      if(this.state.error) {
        resultRewards = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
      } else if(!this.state.isLoaded) {
        resultRewards = <Loading />;
      } else {
        resultRewards = <div className="account-wgt account-wgt__events">
        {/* Table component */}
          <Table
            header={[
              this.props.getTranslate('rewards-NameTable'),
              this.props.getTranslate('rewards-UsersAmountTable'),
              this.props.getTranslate('rewards-StatusTable'),
              ''
            ]}
            handlerRow={this.handlerPermalinkRewards.bind(this)}
            rewardsTable={true}
            bodyData={this.state.rewards}
            bodyDataNames={['name', ['employeeCount', 'allUsers'], 'enabled']}
            controlItemsStatus={true}
            controlItems={{
              'enabled': {'status': true, 'handler': this.handlerEnabled.bind(this), 'label': {'hide': this.props.getTranslate('tableDropdown-StatusLabel'), 'show': this.props.getTranslate('tableDropdown-StatusLabelShow')}},
              'editButton': false,
              'editLink': {
                'status': true,
                'handler': this.props.getPermalink(`/account/rewards/edit/`),
                'label': this.props.getTranslate('tableDropdown-EditLabel')
              },
              'delete': {
                'status': true,
                'handler': this.handlerDeleteRewardsModal.bind(this),
                'label': this.props.getTranslate('tableDropdown-EditRemove')
              }
            }}
            enableTooltipMessage={this.props.getTranslate('tableEnable-TooltipMessage')}
            itemIDName={'rewardID'}
            enabledMobileContent={true}
            mobileContentType={'block'}
            mobileContentHeader={[
              this.props.getTranslate('marketplace-User'),
              this.props.getTranslate('marketplace-Date'),
              this.props.getTranslate('marketplace-Status')
            ]}
            mobileContentData={['name', 'employeeCount', 'enabled']}
            mobileOpenView={true}
            translationValueItems={{
                'ACTIVE': this.props.getTranslate('rewards-StatusActive'),
                'HIDDEN': this.props.getTranslate('rewards-StatusNotActive'),
                'allUsers': this.props.getTranslate('rewards-allUsers')
              }}
            itemEnableName={'enabled'}
            removeIndex={this.state.removeIndex}
          />
        </div>
      }
    return <>

    {/* Query result handler */}
    {this.state.successRewardSubmit ? <ResultQuery
      success={this.state.successRewardSubmit}
      resultText={this.state.successRewardSubmit.text}
      handlerSuccess={this.handlerCloseSuccess}
      handlerError={this.handlerCloseError}
    /> : ''}

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

    {/* Navigation component */}
    <AccountNav
      getPermalink={this.props.getPermalink}
      queryLink={this.props.queryLink}
      configsApp={this.props.configsApp}
      newReview={this.props.newReview}
      companyName={this.props.companyName}
      token={this.props.token()}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      dropdownStatus={this.state.dropdownStatus}
      dropdownHandler={this.handlerDropdown}
      getTranslate={this.props.getTranslate}
      completed={this.props.completed}
    />

    {this.state.navPath && <Navigate
      to={this.props.getPermalink(this.state.navPath)}
      replace={false}
    />}

    <div className="account-content">
      <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          title={this.props.getTranslate('rewards-Title')}
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
        />

        <div className="team-nav team-nav_space_between">

            {/* Button for add event */}
            <Button
              type="link"
              icon={true}
              iconItem="#plus-icon"
              text={this.props.getTranslate('rewards-AddButton')}
              addClass="header-content-nav__btn"
              handler={this.props.getPermalink("/account/rewards/create")}
            />

          </div>

          {this.state.rewards.length < 1 && this.state.isLoaded && !this.state.error ? <>
            <div className="account-content__content account-content__content_empty">
              <div className="empty-wrapper">

                {/* If the response is empty */}
                <AccountContentEmpty
                  img={rewards_img}
                  title={this.props.getTranslate('rewards-EmptyRewardsTitle')}
                  description={this.props.getTranslate('rewards-EmptyRewardsDescription')}
                  buttonType="link"
                  buttonText={this.props.getTranslate('rewards-AddButton')}
                  buttonIcon="#plus-icon"
                  buttonHandler={this.props.getPermalink("/account/rewards/create")}
                />

              </div>
              </div></> : <><div className="account-content__content">

              {/* Query result */}
              {resultRewards}

            </div></>}
            <div className="account-content__footer">

                {/* Pagination component */}
                <Pagination
                  total={this.state.totalPages}
                  perPage="20"
                  path={`${this.props.queryLink}/api/v1/encouragement/rewards/search`}
                  currentPage={this.state.currentPage}
                  items={this.handlerPaginationItems}
                />

              </div>
            <div>
          </div>
        </div>
      </div>
    </div>

    {/* Delete item modal */}
    <DeleteModal
      modalStatus={this.state.modalDeleteRewards}
      modalDisabledStatus={this.state.modalDisabled}
      modalDisabledClass={this.state.addClass}
      handlerModalDisabled={this.handleModalDisabled}
      modalTitle={this.props.getTranslate('modal-deleteRewardTitle')}
      modalDescription={this.props.getTranslate('modal-deleteRewardDescription')}
      modalHandlerDelete={this.handlerDeleteRewards.bind(this, this.state.idDeleteRewards)}
      modalHandlerBtnTxt={this.props.getTranslate('removeModal-ButtonSubmit')}
      modalHandlerBtnLoading={this.state.loadingDeleteSumbit}
    />

    </>;
  }
}
export default Rewards;
