import React from "react";
import Loading from './components/Loading';
import Pagination from './components/Pagination';
import ResultQuery from './components/ResultQuery';
import ResultErrors from './components/ResultErrors';
import { Link } from "react-router-dom";
import AccountNav from './components/AccountNav';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import product_img from './media/product.png';
import no_product_img from './media/no-product-image.jpg';
import { getAuth } from "firebase/auth";
import sprite from './media/icons.svg';
let qw;
if(typeof window !== "undefined" && typeof document !== 'undefined'){
  qw = window.location.protocol;
}
class ProductsNoCategory extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        mobileMenuStart: true,
        mobileMenu: false,
        dropdownStatus: false,
        indexRowUser: null,
        removeIndex: null,
        error: null,
        isLoaded: false,
        products: [],
        totalPages: null,
        currentPage: 0,
        productsEnable: [],
        productEnable: null,
        modalClass: null,
        emptyResult: true,
        modalDisabled: false,
        modal: false,
        modalData: {},
        resultModal: null,
        catalogName: null,
        successText: 'Успешно сохранено',
        successSubmit: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem("successProductEdit") : '',
        sortCurrentTxt: 'modificationDate',
        totalItems: null,
        currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
        controlRow: null,
        companyID: null
      }
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddUser: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 20,
          "direction": "DESC",
          "orderField": "creationDate",
          "withoutCategory": true
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors,
              error: true
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              totalItems: data.totalItems,
              totalPages: data.totalPages,
              products: data.data,
              emptyResult: true
            });
            data.data.map((item, index) => {
              that.setState(prevState => ({
                productsEnable: [...prevState.productsEnable, item.enable]
              }))
            });
          })
        }
      })
    })
  }
  handlerStatus (id, index) {
    let that = this;
    let arr = this.state.productsEnable;
    let newStatus = !arr[index];
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/${id}/enable`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "enable": newStatus,
          "productID": id
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors
            });
          })
        } else {
          arr[index] = newStatus;
          that.setState({
            productsEnable: arr
          });
        }
      })
    })
  }
  handlerDeleteProduct (id, index, event) {
    let that = this;
    if(event.target.closest('.team-table__nav-btn')) {
      this.setState({
        indexRowUser: index,
        removeIndex: index
      });
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/${id}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          that.setState(prevState => ({
            productsEnable: []
          }));
          fetch(`${that.props.queryLink}/api/v1/products/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "itemsPerPage": 20,
              "direction": "DESC",
              "orderField": "modificationDate"
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                });
              })
            } else {
              res.json().then(function(data) {
                that.setState({
                  indexRowUser: null,
                  removeIndex: null,
                  products: data.data,
                  emptyResult: true,
                  totalPages: data.totalPages
                });
                data.data.map((item, index) => {
                  that.setState(prevState => ({
                    productsEnable: [...prevState.productsEnable, item.enable]
                  }))
                });
              })
            }
          })
        }
      })
    })
  }
  handlerSort (sort, sortCurrent, event) {
    let that = this;
    this.setState({
      isLoaded: false
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/search`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "itemsPerPage": 20,
          "productCategoryID": that.props.id,
          "orderField": sort,
          "direction": 'DESC'
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoaded: true,
              errors: data.errors
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              dropdownStatus: false,
              sortCurrentTxt: sortCurrent,
              isLoaded: true,
              totalPages: data.totalPages,
              products: data.data
            });
            data.data.map((item, index) => {
              that.setState(prevState => ({
                productsEnable: [...prevState.productsEnable, item.enable]
              }))
            });
          })
        }
      })
    })
  }
  handlerStatusProductModal (id, event) {
    let that = this;
    let dataProduct = this.state.resultModal;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/${id}/enable`, {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          "enable": dataProduct.enable,
          "productID": id
        })
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          dataProduct.enable = !dataProduct.enable;
          that.setState({
            resultModal: dataProduct
          });
        }
      })
    })
  }
  handlerStatusModal (id) {
    if(this.state.productEnable === id){
      this.setState(prevState => ({
        productEnable: null
      }))
    } else {
      this.setState(prevState => ({
        productEnable: id
      }))
    }
  }
  handleModal (id) {
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/products/${id}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState(prevState => ({
              resultModal: data,
              modal: !prevState.modal,
              modalClass: ''
            }));
          })
        }
      })
    })
  }
  handlerCloseSuccess = () => {
    this.setState(prevState => ({
      successSubmit: false
    }));
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      localStorage.removeItem('successProductEdit');
    }
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }

  /* Pagination handler */
  handlerPaginationItems = (eventsItems, current) => {
    this.setState({
      products: eventsItems,
      currentPage: current
    });
  }
  
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('catalog-withoutCategoryName') + ' | ' + this.props.companyName;
    }
    if(this.state.successSubmit){
      setTimeout(() => {
        this.handlerCloseSuccess();
      }, 5000);
    }
    let resultProducts = '';
    if(this.state.error) {
      resultProducts = <div>{this.props.getTranslate('loading-ErrorRenderContent')}</div>;
    } else if(!this.state.isLoaded) {
      resultProducts = <Loading />;
    } else {
      resultProducts = <div className="product-items">
      {this.state.products.map((item, index) => {
        let classRow = '';
        if(this.state.removeIndex === index){
          classRow = ' row-disabled';
        } else {
          classRow = '';
        }
      return <div className={`product-wgt ${index > 1 && 'product-wgt__border-top-none'}`} key={index}>
        <div className={`product-wgt__inner ${classRow}`}>
          <div className="product-wgt__img-wrap" onClick={this.handleModal.bind(this, item.productID)}>
            <img className="product-wgt__img" src={item.photo ? qw === 'http:' ? item.photo.replace( /https:/g, "http:" ) : item.photo : no_product_img} alt="" />
          </div>
          <div className="product-wgt__content-wrap">
            <h2 className="product-wgt__headline">{item.name}</h2>
            <div className="product-wgt__content">
              <div className="product-wgt__price">{item.price}{this.props.currency ? ' ' + this.props.currency : ''}</div>
              <div className="product-wgt__amount">{item.unlimited ? '100+' : item.stock + ' ' + this.props.getTranslate('catalog-ProductsCount')}</div>
            </div>
            <div className="product-wgt__nav">
            <div className="data-table__control-inner">
            {!this.state.productsEnable[index] ? <div className="data-table__hide">
              <svg className="data-table__hide-icon">
                <use href={`${sprite}#hide-pass`}></use>
              </svg>
              <div className="data-table__hide-content data-table__hide-content-product">{this.props.getTranslate('tableEnable-TooltipMessage')}</div>
            </div> : ''}
            <div className="control-wgt">
              <button className="control-btn" onClick={this.handlerControl.bind(this, index)}>
                <svg className="control-btn__icon control-btn__icon_product">
                  <use href={`${sprite}#more-icon`}></use>
                </svg>
              </button>
              <div className={`control-content ${this.state.controlRow === index ? 'active' : ''}`}>
                <ul className="control-content__list">
                  <li>
                    <button className="control-content__btn" type="button" onClick={this.handlerStatus.bind(this, item.productID, index)} >
                      <svg className="control-content__icon">
                        <use href={`${sprite}#view-icon-stroke`}></use>
                      </svg>
                      {this.state.productsEnable[index] ? this.props.getTranslate('tableDropdown-StatusLabel') : this.props.getTranslate('tableDropdown-StatusLabelShow')}</button>
                  </li>
                  <li>
                    <Link className="control-content__btn" to={this.props.getPermalink(`/account/products/edit/${item.productID}`)}>
                      <svg className="control-content__icon">
                        <use href={`${sprite}#edit-icon`}></use>
                      </svg>{this.props.getTranslate('tableDropdown-EditLabel')}
                    </Link>
                  </li>
                  <li>
                    <button className="control-content__btn control-content__btn_delete" onClick={this.handlerDeleteProduct.bind(this, item.productID, index)}>
                      {this.state.indexRowUser !== index ? <svg className="control-content__icon">
                        <use href={`${sprite}#delete-red`}></use>
                      </svg> : <Loading />}{this.props.getTranslate('tableDropdown-EditRemove')}
                    </button>
                  </li>
                </ul>
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>})}
      </div>;
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    <ResultQuery success={this.state.successSubmit} resultText={this.props.getTranslate('successStatus-Created')} handlerSuccess={this.handlerCloseSuccess} handlerError={this.handlerCloseError} />
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap" onClick={this.handlerCloseElements.bind(this)}>
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
        <div className="account-content__inner">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          backBtn={true}
          backBtnType="link"
          backBtnClass="button-back_theme_gray"
          backBtnHandler={this.props.getPermalink("/account/products")}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        <div className="account-content__header-title">
          <h1 className="account-content__headline">{this.props.getTranslate('catalog-withoutCategoryName')}</h1>
        </div>
        <div className="header-nav-products">
          <div>
            <Link className="btn btn_with_icon" to={this.props.getPermalink(`/account/products/create/${this.props.id}`)}>
              <svg className="btn__icon-add">
                <use href={`${sprite}#plus-icon`}></use>
              </svg>
            {this.props.getTranslate('catalog-AddItemButton')}</Link>
          </div>
          <div className="dropdown dropdown__account-sort">
            <button className="dropdown__btn" onClick={this.handlerDropdown.bind(this)}>
              <div className="dropdown__btn-name">{this.state.sortCurrentTxt === 'modificationDate' ? this.props.getTranslate('catalog-SortByDate') : this.props.getTranslate('catalog-SortByPrice')}</div>
              <svg className="dropdown__icon">
                <use href={`${sprite}#arrow-down`}></use>
              </svg>
            </button>
            <div className={`dropdown-content ${this.state.dropdownStatus ? 'active' : ''}`}>
              <ul className="dropdown-nav">
                <li className="dropdown-nav__item">
                  <button className="dropdown-nav__link active" type="button" onClick={this.handlerSort.bind(this, 'price')}>{this.props.getTranslate('catalog-SortByPrice')}</button>
                </li>
                <li className="dropdown-nav__item">
                  <button className="dropdown-nav__link" type="button" onClick={this.handlerSort.bind(this, 'modificationDate')}>{this.props.getTranslate('catalog-SortByDate')}</button>
                </li>
              </ul>
            </div>
          </div>
       </div>
          {this.state.emptyResult ? <><div className="account-content__content">
            <div className="account-content__content-inner account-content__content-inner-products">
              {resultProducts}
            </div>
          </div>
          <div className="account-content__footer">
            <Pagination total={this.state.totalPages} perPage="20" path={`${this.props.queryLink}/api/v1/products/search`} currentPage={this.state.currentPage} items={this.handlerPaginationItems} />
          </div></> : <div className="account-content__content account-content__content_empty"><div className="empty-wrapper">
            <div className="empty-wrapper__inner">
              <img className="empty-wrapper__img" src={product_img} alt="" />
              <h1 className="empty-wrapper__headline">{this.props.getTranslate('catalog-NullTitle')}</h1>
              <p className="empty-wrapper__description">{this.props.getTranslate('catalog-NullSubTitle')}</p>
              <Link className="btn" to={this.props.getPermalink(`/account/products/create/${this.props.id}`)}>{this.props.getTranslate('catalog-AddItemButton')}</Link>
            </div>
          </div></div>}
        </div>
      </div>
    </div>
    {this.state.modal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline"></h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div className="product-info">
            <div className="tg-list-item">
              <input className="tgl tgl-light" id={`user`} checked={this.state.resultModal.enable ? this.state.resultModal.enable : false} onChange={this.handlerStatusProductModal.bind(this, this.state.resultModal.productID)} type="checkbox" /><label className="tgl-btn" htmlFor={`user`}></label>
            </div>
            <div className="product-info__header">
              <div className="product-info__photo-wrap">
                <img className="product-info__photo" src={this.state.resultModal.photos ? qw === 'http:' ? this.state.resultModal.photos[this.state.resultModal.photos.map(el => el.main).indexOf(true)].url.replace( /https:/g, "http:" ) : this.state.resultModal.photos[this.state.resultModal.photos.map(el => el.main).indexOf(true)].url : no_product_img} alt="" />
              </div>
              <h2 className="product-info__name">{this.state.resultModal.name}</h2>
            </div>
            <div className="product-info__content">
              <table className="product-info__content-table">
              <tbody>
                <tr>
                  <td>{this.props.getTranslate('catalog-ModalProductPrice')}:</td>
                  <td>{this.state.resultModal.price}{this.props.currency ? ' ' + this.props.currency : ''}</td>
                </tr>
                <tr>
                  <td>{this.props.getTranslate('catalog-ModalProductAmount')}:</td>
                  <td>{this.state.resultModal.unlimited ? '100+' : this.state.resultModal.stock}</td>
                </tr>
                </tbody>
              </table>
            </div>
            {this.state.resultModal.description && <div className="product-info__description">
              <p>{this.state.resultModal.description}</p>
            </div>}
            <div className="product-info__nav">
              <div className="product-info__nav-col">
                <Link className="btn btn_theme_secondary btn_size_lg btn_width_100 product-info__nav-btn" to={this.props.getPermalink(`/account/products/edit/${this.state.resultModal.productID}`)}>{this.props.getTranslate('catalog-ModalProductBtnEdit')}</Link>
              </div>
              <div className="product-info__nav-col">
                <button className="btn btn_theme_remove btn_size_lg btn_width_100 product-info__nav-btn" type="button">{this.props.getTranslate('catalog-ModalProductBtnRemove')}</button>
              </div>
            </div>
          </div>
      </div>
    </div> : ''}
</>;
  }
}
export default ProductsNoCategory;
