import React from "react";
import Loading from './components/Loading';
import ResultErrors from './components/ResultErrors';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, Navigate } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import Select from 'react-select';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import img_preview from './media/item-preview-library.png';
import sprite from './media/icons.svg';
import { getAuth } from "firebase/auth";
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const SortableItem = SortableElement(({item, value, handlerDelete, coverLabel}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, item)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">{coverLabel}</span> : ''}<img className="sort-img" src={URL.createObjectURL(value)} alt="" /></div>);
const SortableList = SortableContainer(({items, handlerDelete, coverLabel}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} coverLabel={coverLabel} />
      ))}
    </div>
  );
});
class LibraryCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalDisabled: false,
      modal: false,
      modalEditCategory: false,
      headlineValue: '',
      authorValue: '',
      descriptionValue: '',
      codeValue: '',
      enableValue: true,
      categoriesResult: [],
      categoryValue: '',
      categoryIdValue: null,
      successSubmit: false,
      isLoadedSubmit: false,
      newGategories: [],
      newCategoriesForSubmit: [],
      newCategoriesLoadingSubmit: false,
      editCategoriesIndex: [],
      newCategoriesName: [],
      formData: null,
      percentValue: 0,
      errors: null,
      previewStatus: false,
      objForSubmit: {},
      modalDeleteEvents: false,
      sendNotifications:false,
      codeValueErrorLength: false,
      errorCodeValid: null,
      codeValueErrorNum: false,
      codeValueErrorLessNum: false,
      categoriesNameLength: false,
      errorFields: false,
      errorOnlySpecial: false,
      errorEmptyField: false,
      errorNewCategoryLength: false,
      errorFormatImgs: false,
      errorSizeImgs: false
    }
  }
  handlerDeleteEventModal(event){
    this.setState({
      modalDeleteEvents: true
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/categories`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        res.json().then(function(data) {
          let resultData = data;
          let newObj = resultData.map(function(obj) {
            return {label: obj.name, value: obj.categoryID}
          });
          that.setState( {categoriesResult: newObj} )
        });
      }
    })
  })
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errorSubmit: null,
      errors: null
    }));
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      formData: arrayMoveImmutable(prevState.formData, oldIndex, newIndex)
    }));
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalEditCategory: false,
        modalDeleteEvents: false
      }));
    }, 500);
  }
  handleEditCategories () {
    this.setState(prevState => ({
      modal: !prevState.modal,
      modalEditCategory: true
    }));
  }
  handlerHeadlineValue(event){
    if(this.state.errorNameOnlySpace){
      this.setState({
        errorNameOnlySpace: false
      });
    }
    if(event.target.value.length <= 250){
      this.setState({
        headlineValue: event.target.value,
        inputHeadlineLength: false
      });
    } else {
      this.setState({
        inputHeadlineLength: true
      });
    }
  }
  handlerAuthorValue(event){
    if(this.state.errorAuthorOnlySpace){
      this.setState({
        errorAuthorOnlySpace: false
      });
    }
    if(event.target.value.length <= 50){
      this.setState({
        authorValue: event.target.value,
        authorValueLength: false
      });
    } else {
      this.setState({
        authorValueLength: true
      });
    }
  }
  handlerDescriptionValue(event){
    if(this.state.errorDescriptionOnlySpace){
      this.setState({
        errorDescriptionOnlySpace: false
      });
    }
    if(event.target.value.length <= 500){
      this.setState({
        descriptionValue: event.target.value,
        descriptionValueLength: false
      });
    } else {
      this.setState({
        descriptionValueLength: true
      });
    }
  }
  handlerCodeValue(event) {
    const value = event.target.value;
    const isNumeric = /^[0-9]*$/.test(value);
    if(this.state.codeValueErrorNum){
      this.setState({
        codeValueErrorNum: false
      });
    }
    if(this.state.codeValueErrorLessNum){
        this.setState({
        codeValueErrorLessNum: false
      });
    }
    if (isNumeric && value.length <= 6) {
      this.setState({
        codeValue: value,
        codeValueErrorLength: false
      });
    } else if (!isNumeric) {
      this.setState({
        codeValueErrorNum: true
      });
      return;
    } else {
      this.setState({
        codeValueErrorLength: true
      });
    }
  }
  handlerEnableValue(){
    this.setState(prevState => ({
      enableValue: !prevState.enableValue
    }));
  }
  handlerCategoryValue(event){
    this.setState({
      categoryValue: event.target.value
    });
  }
  handlerSetGategory(event){
    this.setState({
      categoryIdValue: event
    });
  }
  handleFileSelect() {
    var file = this.state.formData;
    var f = file[0];
    var reader = new FileReader();
    reader.onload = (function(theFile) {
        return function(e) {
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            var span = document.querySelector('.account-preview__wgt_col-logo');
            span.innerHTML = ['<img class="thumb" title="', escape(theFile.name), '" src="', e.target.result, '" />'].join('');
          }
        };
    })(f);
    reader.readAsDataURL(f);
  }
  handlerEnableForSubmit(event){
    let obj = this.state.objForSubmit;
    obj.enable = !obj.enable;
    if(!obj.enable){
      obj.sendNotifications = false;
    }
    this.setState({
      objForSubmit: obj
    });
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerNextPreview(event){
    let that = this;
    let error = false;
    that.setState(prevState => ({
      errorFields: false,
      errorNameOnlySpace: false,
      errorAuthorOnlySpace: false,
      errorDescriptionOnlySpace: false,
      errorCodeOnlySpace: false,
      errorCodeValid: null,
      codeValueErrorLessNum: false
    }));
    let nameValue = that.validItemEmpty('headlineValue');
    if(/^\s*$/.test(nameValue) && nameValue !== ''){
      error = true;
      this.setState({errorNameOnlySpace: true });
    }
    let authorValue = that.validItemEmpty('authorValue');
    if(/^\s*$/.test(authorValue) && authorValue !== ''){
      error = true;
      this.setState({errorAuthorOnlySpace: true });
    }
    let descriptionValue = that.validItemEmpty('descriptionValue');
    if(/^\s*$/.test(descriptionValue) && descriptionValue !== ''){
      error = true;
      this.setState({errorDescriptionOnlySpace: true });
    }
    let codeValue = that.validItemEmpty('codeValue');
    if(/^\s*$/.test(codeValue) && codeValue !== ''){
      error = true;
      this.setState({errorCodeOnlySpace: true });
    }
    if(codeValue.length < 6){
      error = true;
      this.setState({codeValueErrorLessNum: true });
    }
    if(error || !nameValue || !authorValue || !descriptionValue || !codeValue){
      error = true;
      that.setState(prevState => ({
        errorFields: true
      }));
      return;
    } else {
      if(typeof window !== "undefined" && typeof document !== 'undefined'){
        document.body.classList.add('preview');
      }
    }
    let location;
    let language = 'uk-UA';
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      location = document.location.pathname;
      const isEnglish = location.startsWith('/en');
      if (isEnglish) {
        language = 'en-US'
      }
    }
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken,
        'Accept-Language': language
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/code`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({code: that.state.codeValue, bookId: null})
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errorCodeValid: data.errors
          });
        })
      } else {
        let obj = {
          "author": authorValue,
          "category": {
            "categoryID": that.state.categoryIdValue ? that.state.categoryIdValue.value : null,
            "name": that.state.categoryIdValue ? that.state.categoryIdValue.label : null
          },
          "code": codeValue,
          "description": descriptionValue,
          "enable": that.state.enableValue,
          "sendNotifications": that.state.sendNotifications,
          "title": nameValue
        };
        that.setState({
          objForSubmit: obj
        });
        if(that.state.formData){
          that.handleFileSelect();
        }
        that.setState(prevState => ({
          previewStatus: !prevState.previewStatus
        }));
      }
    })
  });
}
  handlerSubmit(event){
    event.preventDefault();
    let that = this;
    that.setState({
      isLoadedSubmit: true
    });
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(that.state.objForSubmit)
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            successSubmit: false,
            isLoadedSubmit: false
          });
        })
      } else {
        if(!that.state.formData){
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successLibrarySubmit", JSON.stringify({status: true, text: this.props.getTranslate('successStatus-Save')}));
          }
          that.setState({
            successSubmit: true,
            isLoadedSubmit: false
          });
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
        res.json().then(function(data) {
        if(that.state.formData){
          const myHeaders = new Headers({
            'Authorization': 'Bearer ' + idToken
          });
          async function asyncAwaitWay(that) {
            let formData = new FormData();
            let i = that.state.formData.length;
            let countPercent = 100 / i;
                  for (let file of that.state.formData) {
                    formData.append('file', file);
                    await  fetch(`${that.props.queryLink}/api/v1/library/books/${data.bookID}/photos`, {
                      method: 'POST',
                      headers: myHeaders,
                      body: formData
                    }).then(res => {
                      if(res.status !== 200) {
                        res.json().then(function(data) {
                          that.setState({
                            errors: data.errors
                          });
                        })
                      } else {
                        that.setState(prevState => ({
                          percentValue: (Math.ceil(prevState.percentValue + countPercent))
                        }));
                        i--;
                        if(i < 1){
                          if(typeof window !== "undefined" && typeof document !== 'undefined'){
                            localStorage.setItem("successLibrarySubmit", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}));
                          }
                          that.setState({
                            isLoadingSubmit: false,
                            successSubmit: true
                          });
                          setTimeout(() => {
                            that.setState({
                              successSubmit: false
                            });
                          }, 5000);
                        }
                      }
                    })
                    formData.delete('file');
                  }
          }
          asyncAwaitWay(that);
        }
      });

      }
    })
  })
  }
  handlerCreateCategory(event){
    this.setState(prevState => ({
      categoriesResult: [{"label": "", status: true}, ...prevState.categoriesResult]
    }));
  }
  handerNewGategoryName(index, event){
    let value = event.target.value;
    const newGategories = this.state.newGategories;
    if(value.length <= 40){
      newGategories.splice(index, 1, value);
      this.setState(prevState => ({
        newGategories: newGategories
      }));
    }
  }
  handlerAddCreateCategory(index, event){
    let obj = {label: this.state.newGategories[index]};
    let categoriesResult = this.state.categoriesResult;
    let emptyError = false;
    let specialError = false;
    let categories = this.state.editCategoriesData;
    delete categoriesResult[index].empty;
    delete categoriesResult[index].special;
    let name = categoriesResult[index].label.trim();
    const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(name);
    if(name === ''){
      emptyError= true;
      categories[index].empty = true;
    }
    if(!isValidCurrencyName){
      specialError= true;
      categories[index].special = true;
    }
    if(!emptyError && !specialError){
      delete categoriesResult[index].status;
      delete categoriesResult[index].empty;
      delete categoriesResult[index].special;
      categoriesResult[index].submit = true;
    }
    this.setState(prevState => ({
      categoriesResult: [obj, ...prevState.categoriesResult],
      newCategoriesForSubmit: [...prevState.newCategoriesForSubmit, obj.label],
      newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)]
    }));
  }
  handlerSubmitCategories(event){
    let that = this;
    if(this.state.newCategoriesForSubmit.length > 0 || this.state.newCategoriesName.length > 0){
      this.setState({
        newCategoriesLoadingSubmit: true
      });
    }
    if(this.state.newCategoriesForSubmit){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let categories = that.state.newCategoriesForSubmit;
      async function asyncAwaitWay(that) {
        for (let category of categories) {
          fetch(`${that.props.queryLink}/api/v1/library/books/categories`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({
              "name": category
            })
          }).then(res => {
            if(res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
            } else {
              categories.shift();
              if(categories.length < 1){
                that.setState(prevState => ({
                  newGategories: [],
                  newCategoriesLoadingSubmit: false
                }));
                that.handleModalDisabled();
                fetch(`${that.props.queryLink}/api/v1/library/books/categories`, {
                  method: 'GET',
                  headers: myHeaders
                }).then(res => {
                  if (res.status !== 200) {
                    res.json().then(function(data) {
                      that.setState({
                        errors: data.errors
                      })
                    });
                  } else {
                    res.json().then(function(data) {
                      let resultData = data;
                      let newObj = resultData.map(function(obj) {
                        return {label: obj.name, value: obj.categoryID}
                      });
                      that.setState( {categoriesResult: newObj} )
                    });
                  }
                })
              }
            }
          })
        }
      }
      asyncAwaitWay(that);
    })
    }
    if(this.state.newCategoriesName){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      let categories = that.state.newCategoriesName;
      async function asyncAwaitWay(that) {
        for (let category of categories) {
          fetch(`${that.props.queryLink}/api/v1/library/books/categories/${category.categoryID}`, {
            method: 'PUT',
            headers: myHeaders,
            body: JSON.stringify(category)
          }).then(res => {
            if (res.status !== 200) {
              res.json().then(function(data) {
                that.setState({
                  errors: data.errors
                })
              });
            } else {
                categories.shift();
                if(categories.length < 1){
                  that.setState(prevState => ({
                    newCategoriesName: [],
                    newCategoriesLoadingSubmit: false
                  }));
                  that.handleModalDisabled();
                }
            }
          })
        }
      }
      asyncAwaitWay(that);
    })
    }
  }
  handlerDeleteCategoryById(categoryID, index, event){
    let that = this;
    if(categoryID){
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/library/books/categories/${categoryID}`, {
        method: 'DELETE',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            })
          });
        } else {
          that.setState(prevState => ({
            categoriesResult: prevState.categoriesResult.filter(el => el.value !== categoryID)
          }));
        }
      })
    })
    } else {
      this.setState(prevState => ({
        newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)],
        categoriesResult: [...prevState.categoriesResult.slice(0, index), ...prevState.categoriesResult.slice(index + 1)]
      }));
    }
  }
  handlerDeleteCategory(index, event){
    this.setState(prevState => ({
      newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)]
    }));
  }
  handlerEditCategoryName(index, event){
    this.setState(prevState => ({
      editCategoriesIndex: [...prevState.editCategoriesIndex, index]
    }));
  }
  handlerEditCategoryNameValue(index, event){
    let value = event.target.value;
    const newGategories = this.state.categoriesResult;
    if(value.length <= 40){
      newGategories[index].label = value;
    }
    this.setState(prevState => ({
      categoriesResult: newGategories
    }));
  }
  handlerSaveCategoryName(index, event){
    this.setState(prevState => ({
      newCategoriesName: [...prevState.newCategoriesName, {"categoryID": this.state.categoriesResult[index].value, "name": this.state.categoriesResult[index].label}],
      editCategoriesIndex: prevState.editCategoriesIndex.filter(el => el !== index)
    }));
  }
  handlerAddPhotos(event){
    this.setState(prevState => ({
      errorFormatImgs: false,
      errorSizeImgs: false
    }));
    let error = false;
    let files = event.target.files;
    if(files){
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileName = file.name.toLowerCase();
          const extension = fileName.split('.').pop();
          const fileSizeInMB = file.size / (1024 * 1024);
          if (extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png' && extension !== 'webp') {
              error = true;
              this.setState(prevState => ({
                errorFormatImgs: true
              }));
          }
          if (fileSizeInMB > 20) {
              error = true;
              this.setState(prevState => ({
                errorSizeImgs: true
              }));
          }
      }
    }
    if(!error){
      let formData = this.state.formData;
      if(formData){
        const dt = new DataTransfer();
        for (let file of formData) {
          dt.items.add(file)
        }
        for (let file of files) {
          dt.items.add(file)
        }
        this.setState(prevState => ({
          formData: Array.from(dt.files)
        }));
      } else {
        this.setState(prevState => ({
          formData: Array.from(files)
        }));
      }
    }
  }
  handlerDeleteImg(index, event){
    let formData = this.state.formData;
    formData.splice(index, 1);
    this.setState(prevState => ({
      formData: formData
    }));
  }
  handlerNotificationLibrary(event){
    let obj = this.state.objForSubmit;
    if(obj.enable){
      obj.sendNotifications = !obj.sendNotifications;
    }
    this.setState(prevState => ({
      objForSubmit: obj
    }));
  }

  handlerSetAcceptStatusCategory(index, event){
    let emptyError = false;
    let specialError = false;
    let categories = this.state.categoriesResult;
    delete categories[index].empty;
    delete categories[index].special;
    let name = categories[index].label.trim();
    const isValidCurrencyName = /[a-zA-Z0-9а-яА-Я\s]/.test(name);
    if(name === ''){
      emptyError= true;
      categories[index].empty = true;
    }
    if(!isValidCurrencyName){
      specialError= true;
      categories[index].special = true;
    }
    if(!emptyError && !specialError){
      delete categories[index].status;
      delete categories[index].empty;
      delete categories[index].special;
      categories[index].submit = true;
    }
    this.setState(prevState => ({
      categoriesResult: categories,
      newCategoriesForSubmit: [...prevState.newCategoriesForSubmit, name],
      newGategories: [...prevState.newGategories.slice(0, index), ...prevState.newGategories.slice(index + 1)]
    }));
  }

  handlerSetStatusEditCategory(index, event){
    let categories = this.state.categoriesResult;
    categories[index].status = true;
    this.setState(prevState => ({
      categoriesResult: categories
    }));
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('library-addTitle') + ' | ' + this.props.companyName;
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink('/account/library')} replace={true} />) : ''}
      <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
      <div className="account-wrap">
        <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
        {this.state.previewStatus ? <div className="account-content__inner account-preview">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          preview={true}
          backBtn={true}
          backBtnType="btn"
          backBtnHandler={this.handlerNextPreview.bind(this)}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        <h2 className="account-preview__headline">{this.props.getTranslate('library-previewTitle')}</h2>
        <div className="account-preview__inner account-preview__inner_preview">
          <div className="account-preview__info">
            <div className="account-preview__wgt">
              <div className="account-preview__wgt_row">
                <div className="account-preview__wgt_col-info">
                  <div className="account-preview__wgt-headline">
                    <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.title}</div>
                  </div>
                  <div className="account-preview__info-row account-preview__info-row_space_between">
                    <div className="account-preview__info-wgt">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('library-previewDescriptionLabel')}</div>
                      <div className="account-preview__info-wgt-value" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.description.replace(/\n/g, '<br>')}}></div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('library-previewAuthorLabel')}</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.author}</div>
                    </div>
                    {this.state.categoryIdValue ? <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('library-previewCategoryLabel')}</div>
                      <div className="account-preview__info-wgt-value">{this.state.categoryIdValue.label}</div>
                    </div> : ''}
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('library-previewCodeLabel')}</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.code}</div>
                    </div>
                  </div>
                </div>
                <div className="account-preview__wgt_col-logo"></div>
              </div>
              <div className="account-preview__info-nav">
                <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('library-ModalProductBtnEdit')}</button>
                <button className="button-standart button-standart_theme_red" type="button" onClick={this.handlerDeleteEventModal.bind(this)}>{this.props.getTranslate('library-PreviewBtnRemove')}</button>
              </div>
            </div>
            <div className="account-preview__wgt">
              <div className="account-preview__wgt-headline-text">{this.props.getTranslate('library-previewAdditionalTitle')}</div>
              <div className="field-wrap field-wrap__tooltip">
                <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-0" type="checkbox" checked={!this.state.objForSubmit.enable} onChange={this.handlerEnableForSubmit.bind(this)} /><label htmlFor="styled-checkbox-0"></label></div><span>{this.props.getTranslate('library-PreviewtHideCheckbox')}</span></div>
              </div>
              <div className="field-wrap">
                <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" checked={this.state.objForSubmit.sendNotifications} onChange={this.handlerNotificationLibrary.bind(this)} /><label className={`checkbox-label ${!this.state.objForSubmit.enable ? 'checkbox-label__disabled' : ''}`} htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('library-PreviewNotifyCheckbox')}</span></div>
              </div>
            </div>
            <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmit ? true : false} onClick={this.handlerSubmit.bind(this)}>{this.props.getTranslate('library-PreviewSubmitPulish')}{this.state.isLoadedSubmit && <Loading />}</button>
          </div>
          <div className="account-preview__img">
            <div className="account-preview__img-description">{this.props.getTranslate('library-PreviewExample')}</div>
            <img src={img_preview} alt="" />
          </div>
        </div>
        </div> : <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/library")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

            <div className="account-content__header-title">
              <h1 className="account-content__headline">{this.props.getTranslate('library-addTitle')}</h1>
            </div>
            <div className="library-form-wrap">
              <div className="library-form">
                <div className="library-form__inner">
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('library-addFormTitle')}</label>
                    <input id="headline" className={`field-wrap__input field-wrap__input_width_75 ${this.state.inputHeadlineLength || (this.state.errorFields && !this.state.headlineValue) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.headlineValue !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} placeholder={this.props.getTranslate('library-addFormTitlePlaceholder')} />
                    {this.state.errorFields && !this.state.headlineValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorFields && this.state.errorNameOnlySpace && this.state.headlineValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                    {this.state.inputHeadlineLength ? <div className="validation-error">{this.props.getTranslate('validation-libraryTitleLength')}</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="author">{this.props.getTranslate('library-addFormAuthor')}</label>
                    <input id="author" className={`field-wrap__input ${this.state.authorValueLength || (this.state.errorFields && !this.state.authorValue) || (this.state.errorFields && this.state.errorAuthorOnlySpace && this.state.authorValue !== '') ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.authorValue} onChange={this.handlerAuthorValue.bind(this)} placeholder={this.props.getTranslate('library-addFormAuthorPlaceholder')} />
                    {this.state.errorFields && !this.state.authorValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorFields && this.state.errorAuthorOnlySpace && this.state.authorValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                    {this.state.authorValueLength ? <div className="validation-error">{this.props.getTranslate('validation-libraryAuthorLength')}</div> : ''}
                  </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('library-addFormDescription')}</label>
                    <textarea className={`field-wrap__input field-wrap__input_textarea ${this.state.descriptionValueLength || (this.state.errorFields && !this.state.descriptionValue) || (this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.descriptionValue !== '') ? 'field-wrap__input_error' : ''}`} value={this.state.descriptionValue} onChange={this.handlerDescriptionValue.bind(this)} placeholder={this.props.getTranslate('library-addFormDescriptionPlaceholder')} />
                    {this.state.errorFields && !this.state.descriptionValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                    {this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.descriptionValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                    {this.state.descriptionValueLength ? <div className="validation-error">{this.props.getTranslate('validation-libraryDescirptionLength')}</div> : ''}
                  </div>
                  <div className="board-form__row">
                    <div className="field-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="category">{this.props.getTranslate('library-addFormCategory')}</label>
                      {this.state.categoriesResult ? <><Select
                        styles={this.state.errorFields && !this.state.categoryIdValue ? customStylesError : customStyles}
                        value={this.state.categoriesResult && this.state.categoryIdValue ? this.state.categoriesResult[this.state.categoriesResult.findIndex(el => el.value === this.state.categoryIdValue.value)] : null}
                        options={this.state.categoriesResult}
                        isSearchable={false}
                        onChange={this.handlerSetGategory.bind(this)}
                        placeholder={this.props.getTranslate('library-addFormCategoryPlaceholder')}
                        noOptionsMessage={() => this.props.getTranslate('label-EmptyCategoriesOptions')}
                      />
                      {this.state.errorFields && !this.state.categoryIdValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                      <button className="library-form__categories-edit account-wgt__edit-btn" type="button" onClick={this.handleEditCategories.bind(this)}>{this.props.getTranslate('library-addFormCategoryEditBtn')}</button></> : <input id="category" className="field-wrap__input" type="text" value={this.state.categoryValue} onChange={this.handlerCategoryValue.bind(this)} placeholder="Введите категорию литературы" /> }
                    </div>
                  </div>
                  <div className="board-form__row">
                    <div className="field-wrap">
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="code">{this.props.getTranslate('library-addFormCode')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipLibrary-Code')}</p>
                          </div>
                        </div>
                      </div>
                      <input id="code" className={`field-wrap__input ${this.state.errorCodeValid || this.state.codeValueErrorLength || this.state.codeValueErrorNum || (this.state.errorFields && !this.state.codeValue) || (this.state.errorFields && this.state.errorCodeOnlySpace && this.state.codeValue !== '' || this.state.codeValueErrorLessNum) ? 'field-wrap__input_error' : ''}`} value={this.state.codeValue} onChange={this.handlerCodeValue.bind(this)} type="text" />
                      {this.state.errorCodeValid ? <div className="validation-error">{this.state.errorCodeValid}</div> : ''}
                      {this.state.codeValueErrorLength ? <div className="validation-error">{this.props.getTranslate('validation-LibraryLengthShouldNotCode')}</div> : ''}
                      {this.state.codeValueErrorLessNum && this.state.codeValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-LibraryLengthMustCode')}</div> : ''}
                      {this.state.errorFields && !this.state.codeValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                      {this.state.errorFields && this.state.errorCodeOnlySpace && this.state.codeValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                      {this.state.codeValueErrorNum ? <div className="validation-error">{this.props.getTranslate('validation-OnlyNum')}</div> : ''}
                    </div>
                  </div>
                  <div className="field-wrap">
                  <div className="edit-user-form__imgs">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('library-addFormPhotos')}</label>
                    <div className="edit-user-form__imgs-inner">
                      <div className={`edit-user-upload${this.state.errorFormatImgs || this.state.errorSizeImgs ? ' edit-user-upload-error' : ''}`}>
                        <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                        <label htmlFor="upload">
                        <svg className="upload__icon">
                          <use href={`${sprite}#img-upload`}></use>
                        </svg>
                        {this.props.getTranslate('library-addFormPhotos')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                      </div>
                      {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                        <div className="edit-user-upload__percent">
                          <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                          <div className="edit-user-upload__bar">
                            <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                          </div>
                        </div>
                      </div> : ''}
                      {this.state.formData ? <SortableList items={this.state.formData} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.handlerDeleteImg.bind(this)} coverLabel={this.props.getTranslate('catalog-ItemMainPhoto')} /> : ''}
                    </div>
                  </div>
                  {this.state.errorFormatImgs ? <div className="validation-error">{this.props.getTranslate('validation-AdsImgFormat')}</div> : ''}
                  {this.state.errorSizeImgs ? <div className="validation-error">{this.props.getTranslate('validation-AdsImgSize')}</div> : ''}
                  </div>
                </div>
                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addlibrary-buttonGoPreview')}</button>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
      {this.state.modalEditCategory ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modalCategories-EditTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          <div>
          <div className="edit-category__add-category">
            <button className="btn btn_width_100 btn_theme_transparent btn_size_lg" onClick={this.handlerCreateCategory.bind(this)}>{this.props.getTranslate('modalCategories-addButton')}</button>
          </div>
          {this.state.categoriesResult ? <>
            <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                  renderTrackVertical={props => <div {...props} className="track-vertical-scroll track-vertical" />}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical-scroll thumb-vertical"/>}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={300}
                    renderView={props => <div {...props} />}>
                    <div style={{paddingRight: '15px'}}>
          {this.state.categoriesResult.map((item, index) => {
            return     <div className="categories-faq-wgt" key={index}>
                      <div className="categories-faq-wgt__inner">
                        {item.status ? <input className={`categories-faq-wgt__input ${item.empty || (item.special && !item.empty) || item.label && item.label.length >= 100 ? 'field-wrap__input_error' : ''}`} value={item.label} onChange={this.handlerEditCategoryNameValue.bind(this, index)} placeholder="Введіть назву" /> : <div className="categories-faq-wgt__name">{item.label && item.label.length > 40 ? item.label.substring(0, 40) + "…" : item.label}</div>}
                        <div className="categories-faq-wgt__nav">
                        {item.status ? <button className={`categories-faq-wgt__nav-btn categories-faq-wgt__nav-btn-add`} type="button" onClick={this.handlerSetAcceptStatusCategory.bind(this, index)}>
                          <svg>
                            <use href={`${sprite}#check-icon`}></use>
                          </svg>
                        </button> : ''}
                          {!item.status ? <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerSetStatusEditCategory.bind(this, index)}>
                            <svg>
                              <use href={`${sprite}#edit`}></use>
                            </svg>
                          </button> : ''}
                          <button className="categories-faq-wgt__nav-btn" type="button" onClick={this.handlerDeleteCategoryById.bind(this, item.value, index)}>
                            <svg className={`${item.categoryID ? 'categories-faq-wgt__nav-btn-delete' : 'categories-faq-wgt__nav-btn-close'}`}>
                              <use href={`${sprite}#${item.categoryID ? 'delete' : 'close-icon-category'}`}></use>
                            </svg>
                          </button>
                        </div>
                        </div>
                        {item.label && item.label.length >= 100 && item.status ? <div className="validation-error">{this.props.getTranslate('validation-categoryNameLengthField')}</div> : ''}
                        {item.empty ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        {item.special && !item.empty ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpecialField')}</div> : ''}
                      </div>
          })}</div></Scrollbars></> : ''}
              <div className="field-wrap__submit">
                <button className="btn btn_size_lg btn_width_100 product-info__nav-btn" type="button" onClick={this.handlerSubmitCategories.bind(this)} disabled={this.state.newCategoriesLoadingSubmit ? true : false}>{this.props.getTranslate('modalCategories-SaveButton')}{this.state.newCategoriesLoadingSubmit && <Loading />}</button>
              </div>
          </div>
        </div>
        </div> : ''}
        {this.state.modalDeleteEvents ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
        <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('modal-deleteLibraryTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
            <div className="delete-category-form__description">{this.props.getTranslate('modal-deleteLibraryDescription')}</div>
              <div className="field-wrap__submit">
                <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink('/account/library')}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
              </div>
                </div>
        </div> : ''}
    </>;
  }
}
export default LibraryCreate;
