import React from "react";
import Loading from './components/Loading';
import Table from './components/Table';
import Pagination from './components/Pagination';
import AccountContentHeader from './components/AccountContentHeader';
import AccountContentEmpty from './components/AccountContentEmpty';
import TabItems from './components/TabItems';
import ResultErrors from './components/ResultErrors';
import { Navigate } from "react-router-dom";
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import Select from 'react-select';
import sprite from './media/icons.svg';
import books_img from './media/books.png';
import { getAuth } from "firebase/auth";
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
class AccountLibraryRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      modalDisabled: false,
      modal: false,
      modalOpenRequest: false,
      modalMessageRequest: false,
      messageValue: '',
      messageSubmit: false,
      requests: [],
      totalPages: null,
      currentPage: 0,
      isLoaded: false,
      indexRowRequests: null,
      removeIndex: null,
      requestData: null,
      totalItemsLibrary: null,
      totalItemsReviews: null,
      totalItemsRequests: null,
      errors: null,
      error: false,
      modalDeleteLibrary: false,
      idDeleteLibrary: null,
      controlRow: null,
      errorMessageValueLength: false,
      errorMessageValueOnlySpace: false,
      errorOnlySpecialMessageValue: false
    }
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/requests/search`, {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        "itemsPerPage": 20
      })
    }).then(res => {
      if(res.status === 200) {
        res.json().then(function(data) {
          that.setState({
            isLoaded: true,
            requests: data.data,
            totalPages: data.totalPages,
            totalItemsRequests: data.totalItems
          });
        })
      fetch(`${that.props.queryLink}/api/v1/library/books/reviews/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              })
            })
          } else {
            res.json().then(function(data) {
              that.setState({
                totalItemsReviews: data.totalItems
              })
            });
            fetch(`${that.props.queryLink}/api/v1/library/books/search`, {
              method: 'POST',
              headers: myHeaders,
              body: JSON.stringify({
                "itemsPerPage": 20
              })
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                })
              } else {
                res.json().then(function(data) {
                  that.setState({
                    isLoadedRequests: true,
                    totalItemsLibrary: data.totalItems
                  });
                })
              }
            })
          }
        })
      } else if(res.status === 401) {
        res.json().then(function(data) {
          that.setState({
            errors: [data.message],
            error: true,
            isLoaded: true
          })
        })
      } else {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          })
        });
      }
    })
    })
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false,
        modalOpenRequest: false,
        modalDeleteLibrary: false,
        idDeleteLibrary: null
      }));
    }, 500);
  }
  handleOpenRequest (requestID, index, event) {
    let that = this;
    if(!event.target.closest('.control-wgt')) {
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + idToken
      });
      that.setState(prevState => ({
        modal: !prevState.modal,
        modalOpenRequest: true
      }));
      fetch(`${that.props.queryLink}/api/v1/library/books/requests/${requestID}`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if (res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors
            });
          })
        } else {
          res.json().then(function(data) {
            that.setState({
              requestData: data
            });
          });
        }
      })
    })
    }
  }
  handlerCreateMessageRequest(){
    this.setState(prevState => ({
      modalMessageRequest: true
    }));
  }
  handlerBackRequest(){
    this.setState(prevState => ({
      modalMessageRequest: false
    }));
  }
  handlerMessageRequestValue(event){
      if(this.state.errorOnlySpecialMessageValue){
        this.setState({
          errorOnlySpecialMessageValue: false
        });
      }
      if(event.target.value.length <= 250){
        this.setState({
          messageValue: event.target.value,
          errorMessageValueLength: false
        });
      } else {
        this.setState({
          errorMessageValueLength: true
        });
      }
  }
  handlerPaginationItems = (eventsItems, current) => {
    this.setState({
      requests: eventsItems,
      currentPage: current
    });
  }
  handlerDeleteLibraryModal(id, index, event){
    this.setState({
      modalDeleteLibrary: true,
      removeIndex: index,
      idDeleteLibrary: id
    });
  }
  handlerDeleteRequest(event){
    let that = this;
    that.setState({
      loadingDeleteSumbit: true
    })
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/requests/${that.state.idDeleteLibrary}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        that.setState({
          indexRowLibrary: that.state.removeIndex
        });
        fetch(`${that.props.queryLink}/api/v1/library/books/requests/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            "itemsPerPage": 20,
            "page": that.state.requests.length < 2 ? (that.state.currentPage - 1) : that.state.currentPage
          })
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                error: true
              })
            });
          } else {
            res.json().then(function(data) {
              that.setState({
                indexRowRequests: null,
                removeIndex: null,
                requests: data.data,
                totalPages: data.totalPaged,
                totalItemsRequests: data.totalItems,
                modalDeleteLibrary: false,
                loadingDeleteSumbit: false
              });
            })
          }
        })
      }
    })
    })
  }
  handlerControl(index, event){
    if(index !== this.state.controlRow){
      this.setState({
        controlRow: index
      });
    } else {
      this.setState({
        controlRow: null
      });
    }
  }
  handlerCloseElements(event){
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      const div = document.querySelector( '.control-wgt');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if(div.closest('.control-wgt') && !e.target.closest('.control-wgt')){
          if ( ! withinBoundaries ) {
            this.setState( {
              controlRow: null
            } );
          }
        }
      });
    }
  }
  validItemEmpty = (item) => {
    let itemValue = this.state[item];
    if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
      let onlySpace = /^\s*$/.test(itemValue);
      let itemCorrect;
      if(onlySpace){
        itemCorrect = itemValue;
      } else {
        itemCorrect = itemValue.trim();
      }
      this.setState({ [item]: itemCorrect });
      return itemCorrect;
    }

    return itemValue;
  }
  handlerSubmitMessage(event){
    event.preventDefault();
    let that = this;
    let error = false;
    this.setState(prevState => ({
      errorFields: false,
      errorMessageValueLength: false,
      errorMessageValueOnlySpace: false,
      errorOnlySpecialMessageValue: false
    }));
    let messageValue = that.validItemEmpty('messageValue');
    if(/^\s*$/.test(messageValue) && messageValue !== ''){
      error = true;
      this.setState({errorMessageValueOnlySpace: true });
    }
    const isValidMessageValue = /[a-zA-Z0-9а-яА-Я\s]/.test(messageValue);
    if(!isValidMessageValue){
      error = true;
      this.setState({
        errorOnlySpecialMessageValue: true
      });
    }
    if(!error && messageValue) {
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/requests/${that.state.requestData.bookRequestID}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "answer": messageValue,
        "bookRequestID": that.state.requestData.bookRequestID,
        "comment":  that.state.requestData.comment,
        "link": that.state.requestData.link,
        "status": that.state.requestData.status,
        "title": that.state.requestData.title
      })
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        })
      } else {
        that.setState(prevState => ({
          modalMessageRequest: false,
          messageSubmit: true,
          requestData: {...prevState.requestData, answer: that.state.messageValue}
        }));
      }
    })
  })
} else {
  this.setState({
    errorFields: true
  });
}
  }
  handlerStatusRequest(event){
    this.setState(prevState => ({
      requestData: {...prevState.requestData, status: event.value}
    }));
  }
  handlerSubmitRequest(event){
    event.preventDefault();
    let that = this;
    getAuth().currentUser.getIdToken().then(function(idToken){
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken
    });
    fetch(`${that.props.queryLink}/api/v1/library/books/requests/${that.state.requestData.bookRequestID}/status`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify({
        "bookRequestID": that.state.requestData.bookRequestID,
        "status": that.state.requestData.status
      })
    }).then(res => {
      if (res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        })
      } else {
        let requests = that.state.requests;
        requests[0].status = that.state.requestData.status;
        that.setState({
          requests: requests
        });
        that.handleModalDisabled();
      }
    })
  })
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('library-Title') + ' | ' + this.props.companyName;
    }
    const status = [
      { value: 'NEW', label: this.props.getTranslate('requestLibrary-TableStatusNew') },
      { value: 'IN_PROGRESS', label: this.props.getTranslate('requestLibrary-TableStatusProgress') },
      { value: 'DONE', label: this.props.getTranslate('requestLibrary-TableStatusDone') },
      { value: 'CANCELED', label: this.props.getTranslate('requestLibrary-TableStatusCanceled') }
    ];
    let resultRequest = '';
    if(this.state.error) {
      resultRequest = <div>Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultRequest = <Loading />;
    } else {
      resultRequest = <div className="account-wgt account-wgt__library">

      {/* Table component */}
      <Table
        nameWidth="65%"
        header={[
          this.props.getTranslate('requestLibrary-TableName'),
          this.props.getTranslate('requestLibrary-TableDate'),
          this.props.getTranslate('requestLibrary-TableUser'),
          this.props.getTranslate('requestLibrary-TableStatus'),
          ''
        ]}
        handlerRow={this.handleOpenRequest.bind(this)}
        bodyData={this.state.requests}
        bodyDataNames={['title', 'creationDate', 'userName', 'status']}
        libraryStatus={true}
        controlItemsStatus={true}
        controlItems={{
          'enabled': false,
          'editButton': false,
          'editLink': false,
          'delete': {'status': true, 'handler': this.handlerDeleteLibraryModal.bind(this), 'label': this.props.getTranslate('tableDropdown-EditRemove')}
        }}
        dateValueFormat={{
          start: 'creationDate',
          end: false
        }}
        translationValueItems={{
            'NEW': this.props.getTranslate('requestLibrary-TableStatusNew'),
            'IN_PROGRESS': this.props.getTranslate('requestLibrary-TableStatusProgress'),
            'DONE': this.props.getTranslate('requestLibrary-TableStatusDone'),
            'CANCELED': this.props.getTranslate('requestLibrary-TableStatusCanceled')
          }}
          labelsStatus={{
            'status': {
              'NEW': 'blue',
              'DONE': 'green',
              'IN_PROGRESS': 'yellow',
              'CANCELED': 'red'
            }
          }}
        itemIDName={'bookRequestID'}
        removeIndex={this.state.removeIndex}
      />
</div>;
    }
    return <>
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.requests.length < 1 && this.state.isLoaded ? <Navigate to="/account/library" replace={true} /> : '') : ''}

    {/* Error handler */}
    <ResultErrors
      errors={this.state.errors}
      closeHandler={this.handlerCloseError.bind(this)}
    />

    {/* Header component */}
    <AccountHeader
      queryLink={this.props.queryLink}
      status={this.state.mobileMenu}
      statusStart={this.state.mobileMenuStart}
      handler={this.handlerMobileMenu}
      getTranslate={this.props.getTranslate.bind(this)}
      completed={this.props.completed}
    />

    <div className="account-wrap">

      {/* Navigation component */}
      <AccountNav
        getPermalink={this.props.getPermalink}
        queryLink={this.props.queryLink}
        configsApp={this.props.configsApp}
        newReview={this.props.newReview}
        companyName={this.props.companyName}
        token={this.props.token()}
        status={this.state.mobileMenu}
        statusStart={this.state.mobileMenuStart}
        handler={this.handlerMobileMenu}
        dropdownStatus={this.state.dropdownStatus}
        dropdownHandler={this.handlerDropdown}
        getTranslate={this.props.getTranslate}
        completed={this.props.completed}
      />

      <div className="account-content">
        <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            title={this.props.getTranslate('library-Title')}
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
          />

          {this.state.requests.length < 1 && this.state.isLoaded && !this.state.error ? <>
            <div className="account-content__content account-content__content_empty">
              <div className="empty-wrapper">

                {/* If the response is empty */}
                <AccountContentEmpty
                  img={books_img}
                  title={this.props.getTranslate('requestLibrary-EmptyRequestTitle')}
                  description={this.props.getTranslate('requestLibrary-EmptyRequestDescription')}
                />

              </div>
              </div></> : <><div className="account-content__content">

                {/* Tabs component */}
                {this.state.isLoaded ?

                <TabItems items={[
                  {
                    label: this.props.getTranslate('library-ReferencesTab'),
                    path: this.props.getPermalink('/account/library'),
                    amount: this.state.totalItemsLibrary,
                    disabled: false
                  },
                  {
                    label: this.props.getTranslate('library-ReviewTab'),
                    path: this.props.getPermalink('/account/library/reviews'),
                    amount: this.state.totalItemsReviews,
                    disabled: this.state.totalItemsReviews === 0 ? true : false,
                    className: this.props.newReview && this.props.newReview > 0 ? 'tab_theme_green' : ''
                  },
                  {
                    label: this.props.getTranslate('library-RequestTab'),
                    path: this.props.getPermalink('/account/library/request'),
                    amount: this.state.totalItemsRequests,
                    disabled: this.state.totalItemsRequests === 0 ? true : false
                  }
                ]} /> : ''}

                {/* Query result */}
                {resultRequest}

              </div></>}

          <div className="account-content__footer">

            {/* Pagination component */}
            <Pagination
              total={this.state.totalPages}
              perPage="20"
              path={`${this.props.queryLink}/api/v1/library/books/requests/search`}
              currentPage={this.state.currentPage}
              items={this.handlerPaginationItems}
            />

          </div>
        </div>
      </div>
    </div>
    {this.state.modalOpenRequest ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
        {this.state.modalMessageRequest ? <>
          <div className="modal-header modal-header__center">
            <button className="modal-header__back-btn" type="button" onClick={this.handlerBackRequest.bind(this)}>
              <svg className="modal-header__back-icon">
                <use href={`${sprite}#arrow-back`}></use>
              </svg>
            </button>
            <h2 className="modal-headline">{this.props.getTranslate('requestLibrary-modalRequestMessageTitle')}</h2>
          </div>
          <div>
            <form onSubmit={this.handlerSubmitMessage.bind(this)}>
              <div className="field-wrap">
                <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="messages">{this.props.getTranslate('requestLibrary-modalRequestMessageText')}</label>
                <textarea className={`field-wrap__input field-wrap__input_request-textarea ${(this.state.errorOnlySpecialMessageValue && this.state.messageValue !== '') || (this.state.errorFields && !this.state.messageValue) || this.state.errorMessageValueLength || this.state.errorMessageValueOnlySpace ? 'field-wrap__input_error' : ''}`} value={this.state.messageValue} onChange={this.handlerMessageRequestValue.bind(this)} />
                {this.state.errorFields && this.state.errorOnlySpecialMessageValue && this.state.messageValue !== '' ? <div className="validation-error">{this.props.getTranslate('validation-messageRequestOnlySpecialField')}</div> : ''}
                {this.state.errorFields && !this.state.messageValue ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                {this.state.errorMessageValueLength ? <div className="validation-error">{this.props.getTranslate('validation-messageRequestMessageLength')}</div> : ''}
                {this.state.errorMessageValueOnlySpace ? <div className="validation-error">{this.props.getTranslate('validation-messageRequestMessageOnlySpace')}</div> : ''}
              </div>
              <div className="request-modal__submit">
                <button className="btn btn_size_lg btn_width_100" type="submit">{this.props.getTranslate('requestLibrary-modalRequestSendMessageBtn')}</button>
              </div>
            </form>
          </div>
          </> : <>
          <div className="modal-header">
            <h2 className="modal-headline">{this.props.getTranslate('requestLibrary-modalRequestTitle')}</h2>
            <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
              <svg className="close-modal__icon">
                <use href={`${sprite}#close-icon`}></use>
              </svg>
            </button>
          </div>
          {this.state.requestData ? <div className="modal-content">
            <div className="request-modal__content">
              <div className="request-modal-wgt">
                <div className="request-modal-wgt__name">{this.props.getTranslate('requestLibrary-modalRequestAuthor')}</div>
                <div className="request-modal-wgt__value request-modal-wgt__value_lg">{this.state.requestData.userName}</div>
              </div>
              <div className="request-modal-wgt">
                <div className="request-modal-wgt__name">{this.props.getTranslate('requestLibrary-modalRequestLiterature')}</div>
                <div className="request-modal-wgt__value request-modal-wgt__value_lg">{this.state.requestData.title}</div>
              </div>
              {this.state.requestData.link ? <div className="request-modal-wgt">
                <div className="request-modal-wgt__name">{this.props.getTranslate('requestLibrary-modalRequestLink')}</div>
                <div className="request-modal-wgt__value">
                  <a className="request-modal-wgt__value-link" href={this.state.requestData.link} target="_blank" rel="noopener noreferrer">{this.state.requestData.link}</a>
                </div>
              </div> : ''}
              {this.state.requestData.comment ? <div className="request-modal-wgt">
                <div className="request-modal-wgt__name">{this.props.getTranslate('requestLibrary-modalRequestComment')}</div>
                <div className="request-modal-wgt__value">
                  <p>{this.state.requestData.comment}</p>
                </div>
              </div> : ''}
            </div>
            <form onSubmit={this.handlerSubmitRequest.bind(this)}>
            <div className="request-modal__status">
              <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('requestLibrary-modalRequestStatus')}</label>
              <Select
                styles={customStyles}
                options={status}
                isSearchable={false}
                value={status[status.findIndex(el => el.value === this.state.requestData.status)]}
                onChange={this.handlerStatusRequest.bind(this)}
                placeholder='Выберите из списка'
              />
            </div>
            {this.state.requestData.answer ? <div className="request-modal__message-wrap"><label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('requestLibrary-modalRequestComment')}</label><div className="request-modal__message">
              <p>{this.state.requestData.answer}</p>
            </div></div> : <div className="request-modal__message-btn">
              <button className="btn btn_width_100 btn_theme_transparent" onClick={this.handlerCreateMessageRequest.bind(this)}>{this.props.getTranslate('requestLibrary-modalRequestSendMessageBtn')}</button>
            </div>}
            <div className="request-modal__submit">
              <button className="btn btn_size_lg btn_width_100" type="submit">{this.props.getTranslate('requestLibrary-modalRequestSaveBtn')}</button>
            </div></form>
          </div> : ''}</>}
        </div>
      </div> : ''}

      {this.state.modalDeleteLibrary ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('modal-deleteRequestLibraryTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
          <div className="delete-category-form__description">После удаления категории «Название категории» все вложенные вопросы будут перемещены в раздел скрытые и не будут отображаться в приложении</div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" type="button" disabled={this.state.loadingDeleteSumbit? true : false} onClick={this.handlerDeleteRequest.bind(this)}>{this.props.getTranslate('removeModal-ButtonSubmit')}{this.state.loadingDeleteSumbit && <Loading />}</button>
            </div>
      </div>
      </div> : ''}

    </>;
  }
}
export default AccountLibraryRequest;
