import React from "react";
import GetEmoji from './components/GetEmoji';
import AccountContentHeader from './components/AccountContentHeader';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Loading from './components/Loading';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { useParams, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import AccountHeader from './components/AccountHeader';
import AccountNav from './components/AccountNav';
import CalendarDropdown from './components/CalendarDropdown';
import sprite from './media/icons.svg';
import img from './media/img-event-2.png';
import member_1 from './media/avatar-1.png';
let Editor;
let EditorState;
let ContentState;
let convertFromHTML;
let stateToHTML;

if (typeof window !== 'undefined') {
  Editor = require('react-draft-wysiwyg').Editor;
  EditorState = require('draft-js').EditorState;
  ContentState = require('draft-js').ContentState;
  convertFromHTML = require('draft-js').convertFromHTML;
  stateToHTML = require('draft-js-export-html').stateToHTML;

  require('../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css');
}
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const type = [
  { value: 'NORMAL', label: 'Стандартный' },
  { value: 'QUICK', label: 'Быстрый' }
];
const SortableItem = SortableElement(({item, value, handlerDelete}) => <div><button className="edit-product-img__remove" type="button" onClick={handlerDelete.bind(this, value.photoID)}><svg className="edit-product-img__remove-icon"><use href={`${sprite}#close-icon`}></use></svg></button><span className="product-num">{item + 1}</span>{item < 1 ? <span className="product-label">Обложка</span> : ''}<img src={value.url} alt="" /></div>);

const SortableList = SortableContainer(({items, handlerDelete}) => {
  return (
    <div className="edit-product-img">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} item={index} index={index} value={value} handlerDelete={handlerDelete} />
      ))}
    </div>
  );
});
class EventsEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      isLoadedSubmit: false,
      headlineValue: '',
      descriptionValue: '',
      repeatValue: '',
      typeValue: '',
      placeValue: '',
      addressValue: '',
      confirmation: false,
      open: false,
      status: '',
      users: [],
      usersGet: [],
      errorUser: null,
      isLoadedUser: false,
      modalDisabled: false,
      modal: false,
      modalAddMembers: false,
      chooseAllMembers: false,
      chooseMembersIds: [],
      photos: [],
      contentState: {},
      cardList: [img],
      isLoaded: false,
      isLoadedDelete: false,
      successSubmit: false,
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      formData: null,
      oldDateStart: null,
      oldDateEnd: null,
      percentValue: 0,
      searchValue: '',
      usersSearch: [],
      invitations: [],
      smileValue: null,
      getSmileModal: false,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      errors: null,
      error: false,
      diffInMonths: null,
      setInput: false,
      bonuseValue: '',
      bonuseConfirmation: false,
      startDateCalendar: null,
      endDateCalendar: null,
      startTimeCalendar: '00:00',
      endTimeCalendar: '22:00',
      iterStartCalendar: null,
      iterFinishCalendar: null,
      weeklyDay: null,
      nthWeekday: null,
      dateNum: null,
      monthNum: null,
      eventsReccuring: [
        { value: null, label: this.props.getTranslate('events-RepeaNull')},
        { value: 'DAILY', label: this.props.getTranslate('events-RepeatDay') },
        { value: 'WEEKLY', label: this.props.getTranslate('events-RepeatWeek') },
        { value: 'MONTHLY', label: this.props.getTranslate('events-RepeatMonth') },
      ],
      changeStatus: false,
      getUsersIdsHover: [],
      bonuseValueNumError: false,
      bonuseValueCountError: false
    }
  }
  getNameDay(num){
    const days = [
      'неділя',
      'понеділок',
      'вівторок',
      'середа',
      'четвер',
      'п\'ятниця',
      'субота'
    ];
    return days[num];
  }
  getMonthsName(num){
    const monthes = [
      'січня',
      'лютого',
      'березня',
      'квітня',
      'травня',
      'червня',
      'липня',
      'серпня',
      'вересня',
      'жовтня',
      'листопада',
      'грудня'
    ];
    return monthes[num];
  }
  handlerGetDate (event) {
    event.preventDefault();
    let dateFull = new Date(Date.parse(this.state.oldDateEnd));
    let dateFull2 = new Date(Date.parse(this.state.oldDateStart));

    const diffInMonths = (dateFull.getFullYear() - dateFull2.getFullYear()) * 12 + (dateFull.getMonth() - dateFull2.getMonth());
    let fullYear = dateFull.getFullYear();
    let month = dateFull.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      diffInMonths: diffInMonths,
      dateModal: !prevState.dateModal,
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      countFirstElems: unshiftElemsNum,
      date: new Date(dateFull.getFullYear(), dateFull.getMonth(), dateFull.getDate(), dateFull.getHours(), dateFull.getMinutes(), dateFull.getSeconds(), '0'),
      iterStart: diffInMonths === 0 ? unshiftElemsNum + dateFull2.getDate() : 1,
      iterStartSave: diffInMonths === 0 ? unshiftElemsNum + dateFull2.getDate() : 1,
      iterFinish: unshiftElemsNum + dateFull.getDate(),
      countMonth: diffInMonths === 0 ? 1 : diffInMonths,
      countMonthFinish: diffInMonths === 0 ? 1 : diffInMonths,
      countMonthSave: diffInMonths === 0 ? 1 : 0,
      month: month,
      finishStartDate: (dateFull2.getDate() < 10 ? '0' + dateFull2.getDate() : dateFull2.getDate()) + '.' + (dateFull2.getMonth() + 1 < 10 ? '0' + (dateFull2.getMonth() + 1) : dateFull2.getMonth() + 1) + '.' + dateFull2.getFullYear(),
      finishEndDate:  (dateFull.getDate() < 10 ? '0' + dateFull.getDate() : dateFull.getDate()) + '.' + (dateFull.getMonth() + 1 ? '0' + (dateFull.getMonth() + 1) : dateFull.getMonth() + 1) + '.' + dateFull.getFullYear(),
      startTime: (dateFull2.getHours() < 10 ? '0' + dateFull2.getHours() : dateFull2.getHours()) + ':' + (dateFull2.getMinutes() < 10 ? '0' + dateFull2.getMinutes() : dateFull2.getMinutes()),
      endTime: (dateFull.getHours() < 10 ? '0' + dateFull.getHours() : dateFull.getHours())  + ':' + (dateFull.getMinutes() < 10 ? '0' + dateFull.getMinutes() : dateFull.getMinutes()),
    }));
  }
  setDateInput (event) {
    event.preventDefault();
    this.setState(prevState => ({
      chooseDateFull: prevState.finishStartDate + ' ' + (prevState.startTime ? prevState.startTime : '00:00') + ' - ' + (prevState.finishEndDate ? prevState.finishEndDate + ' ' + (prevState.endTime ? prevState.endTime : '23:59') : prevState.finishStartDate + ' ' + (prevState.endTime ? prevState.endTime : '23:59')),
      startTime: prevState.startTime ? prevState.startTime : '00:00',
      endTime: prevState.endTime ? prevState.endTime : '23:59',
      dateModal: !prevState.dateModal,
      setInput: true
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let dateFull2 = new Date(Date.parse(this.state.oldDateStart));
    let date = this.state.date;
    let fullYear = date.getFullYear();
    let month = date.getMonth() - 1;
    let dateDay = 1;
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    if(this.state.diffInMonths === 0){
      this.setState(prevState => ({
        iterStart: unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: unshiftElemsNum + dateFull2.getDate(),
      }))
    } else {
      this.setState(prevState => ({
        iterStart: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
      }))
    }
    this.setState(prevState => ({
      date: new Date(fullYear, month, dateDay),
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let dateFull2 = new Date(Date.parse(this.state.oldDateStart));
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    if(this.state.diffInMonths === 0){
      this.setState(prevState => ({
        iterStart: unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: unshiftElemsNum + dateFull2.getDate(),
      }))
    } else {
      this.setState(prevState => ({
        iterStart: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
        iterStartSave: prevState.countMonth > prevState.countMonthFinish ? 1 : unshiftElemsNum + dateFull2.getDate(),
      }))
    }
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec'),
    ];    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        oldDateStart: year + '-' + month + '-' + event.target.innerHTML
      }));
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth,
        oldDateStart: year + '-' + month + '-' + event.target.innerHTML
      }));
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        oldDateEnd: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML
      }));
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth,
        oldDateEnd: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML
      }));
    }
  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode === 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode === 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerStartTime (event) {
    event.preventDefault();
    this.setState({
      startTime: event.target.value
    });
  }
  handlerEndTime (event) {
    event.preventDefault();
    this.setState({
      endTime: event.target.value
    });
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(prevState => ({
      photos: arrayMoveImmutable(prevState.photos, oldIndex, newIndex)
    }));
  }
  onEditorStateChange = (contentState) => {
    this.setState({
      contentState: contentState
    });
  };
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id, event){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      this.setState(prevState => ({
        getUsersIds: prevState.getUsersIds.filter((_, i) => i !== indexArr)
      }));
    } else {
      this.setState(prevState => ({
        getUsersIds: [...prevState.getUsersIds, {id: id, name: name}]
      }));
    }
  }
  handlerRemoveAllUsers(){
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id, event){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      usersIds: ids
    }));
  }
  handlerSetUsers(){
    let ids = [];
    ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
    this.setState(prevState => ({
      allUsers: this.state.getAllUsers ? true : false,
      usersIds: ids,
      usersGet: this.state.getUsersIds,
      invitations: ids
    }));
  this.handleModalDisabled();
  }
  handlerHeadlineValue(event) {
    this.setState({
      headlineValue: event.target.value
    });
  }
  handlerDescriptionValue(event) {
    this.setState({
      descriptionValue: event.target.value
    });
  }
  handlerRepeatEvent(event){
    this.setState({
      repeatValue: event.value
    });
  }
  handlerFullDateValue(event){
    let dateArr = event.target.value.split(' - ');
    this.setState({
      startDate: dateArr[0],
      endDate: dateArr[1]
    });
  }
  handlerTypeEvent(event){
    this.setState({
      typeValue: type[type.findIndex(el => el.value === event.value)]
    });
  }
  handlerPlaceValue(event){
    this.setState({
      placeValue: event.target.value
    });
  }
  handlerLinkValue(event){
    this.setState({
      linkValue: event.target.value
    });
  }
  handlerAddressValue(event){
    this.setState({
      addressValue: event.target.value
    });
  }
  handlerConfirmation(event) {
    this.setState(prevState => ({
      confirmation: !prevState.confirmation
    }));
  }
  handlerOpen(event) {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }
  handlerBonuseValue(event) {
      const inputValue = event.target.value;
      if (inputValue === "") {
          this.setState({
              bonuseValue: "",
              bonuseValueNumError: false,
              bonuseValueCountError: false
          });
          return;
      }
      if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
          const floatValue = parseFloat(inputValue);
          if (!isNaN(floatValue)) {
              if (floatValue > 999999.99) {
                  this.setState({
                      bonuseValueCountError: true,
                      bonuseValueNumError: false
                  });
              } else {
                  this.setState({
                      bonuseValue: inputValue,
                      bonuseValueNumError: false,
                      bonuseValueCountError: false
                  });
              }
          } else {
              this.setState({
                  bonuseValueCountError: false,
                  bonuseValueNumError: true
              });
          }
      } else {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
          this.setState({
              bonuseValueCountError: true
          });
        } else {
          this.setState({
              bonuseValueNumError: true
          });
        }
      }
  }
  handlerBonuseConfirmation(event){
    this.setState(prevState => ({
      bonuseConfirmation: !prevState.bonuseConfirmation
    }));
  }
  dateFormating(start, end){
    let dateStart = new Date(Date.parse(start));
    let dateEnd = new Date(Date.parse(end));
    let valueDateStart = dateStart.getDate();
    let valueMonthStart = dateStart.getMonth() + 1;
    let valueYearStart = dateStart.getFullYear();
    let valueHoursStart = dateStart.getHours();
    let valueMinutesStart = dateStart.getMinutes();
    let valueDateEnd = dateEnd.getDate();
    let valueMonthEnd = dateEnd.getMonth() + 1;
    let valueYearEnd = dateEnd.getFullYear();
    let valueHoursEnd = dateEnd.getHours();
    let valueMinutesEnd = dateEnd.getMinutes();
    if(valueDateStart < 10){
      valueDateStart = '0' + valueDateStart;
    }
    if(valueMonthStart < 10){
      valueMonthStart = '0' + valueMonthStart;
    }
    if(valueHoursStart < 10){
      valueHoursStart = '0' + valueHoursStart;
    }
    if(valueMinutesStart < 10){
      valueMinutesStart = '0' + valueMinutesStart;
    }
    if(valueDateEnd < 10){
      valueDateEnd = '0' + valueDateEnd;
    }
    if(valueMonthEnd < 10){
      valueMonthEnd = '0' + valueMonthEnd;
    }
    if(valueHoursEnd < 10){
      valueHoursEnd = '0' + valueHoursEnd;
    }
    if(valueMinutesEnd < 10){
      valueMinutesEnd = '0' + valueMinutesEnd;
    }
    if(end){
      return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ' ' + valueHoursStart + ':' + valueMinutesStart + ' – ' + valueDateEnd + '.' + valueMonthEnd + '.' + valueYearEnd + ' ' + valueHoursEnd + ':' + valueMinutesEnd;
    } else {
      return valueDateStart + '.' + valueMonthStart + '.' + valueYearStart + ', ' + valueHoursStart + ':' + valueMinutesStart;
    }
  }
  componentDidMount() {
    if(document.body.classList.contains('preview')){
      document.body.classList.remove('preview');
    }
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/events/${that.props.id}`, {
      method: 'GET',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors,
            error: true,
            isLoaded: true
          });
        })
      } else {
        res.json().then(function(data) {
          let startDate = new Date(data.startTime);
          let startDateFull = startDate.getDate() + '.' + (startDate.getMonth() + 1) + '.' + startDate.getFullYear();
          let weeklyDayStart = startDate.getDay();
          let nthWeekdayStart = that.getNthWeekdayOfMonth(new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));

          let finishDate = new Date(data.endTime);
          let finishDateFull = finishDate.getDate() + '.' + (finishDate.getMonth() + 1) + '.' + finishDate.getFullYear();

          that.setState( {
            startDateCalendar: startDateFull,
            endDateCalendar: finishDateFull,
            startTimeCalendar: (startDate.getHours() < 10 ? '0' + startDate.getHours() : startDate.getHours()) + ':' + (startDate.getMinutes() < 10 ? '0' + startDate.getMinutes() : startDate.getMinutes()),
            endTimeCalendar: (finishDate.getHours() < 10 ? '0' + finishDate.getHours() : finishDate.getHours()) + ':' + (finishDate.getMinutes() < 10 ? '0' + finishDate.getMinutes() : finishDate.getMinutes()),
            weeklyDay: weeklyDayStart,
            nthWeekday: nthWeekdayStart - 1,
            dateNum: startDate.getDate(),
            monthNum: startDate.getMonth()
          } );
          that.setState({
            allUsers: false,
            headlineValue: data.title,
            descriptionValue: data.description,
            typeValue: type[type.findIndex(el => el.value === data.eventType)],
            repeatValue: that.state.eventsReccuring[that.state.eventsReccuring.findIndex(el => el.value === data.recurringType)].value,
            addressValue: data.address,
            placeValue: data.place,
            open: data.openEvent,
            linkValue: data.link,
            status: data.eventStatus,
            photos: data.photos,
            chooseDateFull: that.dateFormating(data.startTime, data.endTime),
            endTime: data.endTime,
            startTime: data.startTime,
            oldDateEnd: data.endTime,
            oldDateStart: data.startTime,
            smileValue: data.smile,
            bonuseValue: data.bonus,
            bonuseConfirmation: data.bonusConfirmation,
            isLoaded: true,
            invitations: data.invitations,
            usersIds: data.invitations ? data.invitations.map(x => x.userID) : null,
            contentState: (typeof window !== "undefined" && typeof document !== 'undefined') ? EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(data.description)
              )
            ) : ''
          });
        fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
          method: 'GET',
          headers: myHeaders
        }).then(res => {
          if(res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors
              });
            })
          } else {
            res.json().then(function(data) {
              let users = data.map(x => x.users);
              let usersIDs = users.map(x => {
                return x.map(id => {
                  return {id: id.userID, name: id.name};
                })
              });
              var result3 = [].concat(...usersIDs);
              let ids = [...new Set(that.state.invitations.map(users => users.userID))];
              var usersActive = result3.map(user => {
                if(ids.indexOf(user.id) !== -1){
                  return user;
                }
              });
              usersActive = usersActive.filter(function(x) {
                   return x !== undefined;
              });
              that.setState({
                usersGet: usersActive,
                getUsersIds: usersActive
              });
            })
          }
        })
        });
      }
    })
  }
  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(!event.target.closest('.select-users__label')){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.props.token()
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              errorUsers: data.errors,
              errors: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              users: data
            })
          });
        }
      })
    }
  }

  handlerAddPhotos(event){
    let files = event.target.files;
    let formData = this.state.formData;
    if(formData){
      const dt = new DataTransfer();
      for (let file of formData) {
        dt.items.add(file)
      }
      for (let file of files) {
        dt.items.add(file)
      }
      this.setState(prevState => ({
        formData: Array.from(dt.files)
      }));
    } else {
      this.setState(prevState => ({
        formData: Array.from(files)
      }));
    }
  }
  handlerChooseMember (member, id, event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    let arrIds = this.state.chooseMembersIds;
    let index = arr.indexOf(member);
    let indexIds = arrIds.indexOf(id);
    if(index !== -1){
      arr.splice(index, 1);
    } else {
      arr.push(member);
    }
    if(indexIds !== -1){
      arrIds.splice(indexIds, 1);
    } else {
      arrIds.push(id);
    }
    this.setState(prevState => ({
      chooseMembers: arr,
      chooseMembersIds: arrIds
    }));
  }
  setChooseMembers (event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    let result = arr.map((item, index) => {
      return <div className="select-modal__item" key={index} onClick={this.removeMember.bind(this, index)}>{item}<span>&#x2716;</span></div>
    });
    this.setState(prevState => ({
      memberItems: result
    }));
    this.handleModalDisabled();
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddMembers: false,
        modalDisabled: false,
        modalData: {}
      }));
    }, 500);
  }
  handlerEventSubmit (event) {
    event.preventDefault();
    let that = this;
    this.setState({
      isLoadedSubmit: true
    });
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    let obj = {
      "address": this.state.addressValue,
      "allUsers": this.state.getAllUsers,
      "bonus": parseInt(this.state.bonuseValue),
      "bonusConfirmation": this.state.bonuseConfirmation,
      "description": stateToHTML(this.state.contentState.getCurrentContent()),
      "endTime": this.state.endDateCalendar ? (this.state.endDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00') : (this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00'),
      "eventType": 'EVENT',
      "eventID": this.props.id,
      "openEvent": this.state.open,
      "place": this.state.placeValue,
      "smile": this.state.smileValue,
      "status": this.state.status,
      "recurringType": this.state.repeatValue,
      "startTime": this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.startTimeCalendar + ':00',
      "title": this.state.headlineValue,
      "userIds": this.state.usersIds
    };
    obj.startTime = new Date(obj.startTime).toISOString();
    obj.endTime = new Date(obj.endTime).toISOString();
    fetch(`${that.props.queryLink}/api/v1/events/${this.props.id}`, {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(obj)
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            isLoadedSubmit: false,
            errors: data.errors ? data.errors : data
          })
        })
      } else {
        if(!that.state.formData){
          that.setState({
            isLoadedSubmit: false,
            successSubmit: true
          });
          if(typeof window !== "undefined" && typeof document !== 'undefined'){
            localStorage.setItem("successEventSubmit", JSON.stringify({status: true, text: that.props.getTranslate('successStatus-Save')}))
          }
          setTimeout(() => {
            that.setState({
              successSubmit: false
            });
          }, 5000);
        }
        if(that.state.formData){
          const myHeaders = new Headers({
            'Authorization': 'Bearer ' + that.props.token()
          });
          let formData = new FormData();
          let i = that.state.formData.length;
          let countPercent = 100 / i;
          for (let file of that.state.formData) {
            formData.append('file', file);
            fetch(`${that.props.queryLink}/api/v1/events/${that.props.id}/photos`, {
              method: 'POST',
              headers: myHeaders,
              body: formData
            }).then(res => {
              if(res.status !== 200) {
                res.json().then(function(data) {
                  that.setState({
                    errors: data.errors
                  })
                })
              } else {
                res.json().then(function(data) {
                  that.setState(prevState => ({
                    percentValue: (Math.ceil(prevState.percentValue + countPercent))
                  }));
                  i--;
                  if(i < 1){
                    that.setState({
                      isLoadedSubmit: false,
                      successSubmit: true
                    });
                    if(typeof window !== "undefined" && typeof document !== 'undefined'){
                      localStorage.setItem("successEventSubmit", JSON.stringify({status: true, text: 'Успешно сохранено'}))
                    }
                    setTimeout(() => {
                      that.setState({
                        successSubmit: false
                      });
                    }, 5000);
                  }
                })
              }
            })
            formData.delete('file');
          }
          Promise.all(this.state.formData).then(function() {
            console.log (i);
          });
        };
      }
    })
  }
  hanlderRemoveProductImage(id, event){
    let that = this;
    const myHeaders = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.props.token()
    });
    fetch(`${that.props.queryLink}/api/v1/events/${this.props.id}/photos/${id}`, {
      method: 'DELETE',
      headers: myHeaders
    }).then(res => {
      if(res.status !== 200) {
        res.json().then(function(data) {
          that.setState({
            errors: data.errors
          })
        });
      } else {
        let photos = that.state.photos;
        let index = photos.map(el => el.photoID).indexOf(id);
        photos.splice(index, 1);
        that.setState(prevState => ({
          prevState: [...prevState.photos, photos]
        }));
      }
    })
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }
  handlerCloseError (event) {
    event.preventDefault();
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerStatus(event) {
    this.setState({
      status: event.value
    });
  }


  handlerGetStartDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(year, (monthValue - 1), day));
    this.setState({
      startDateCalendar: day + '.' + monthValue + '.' + year,
      iterStartCalendar: iter,
      weeklyDay: new Date(year, (monthValue - 1), day).getDay(),
      nthWeekday: nthWeekday - 1,
      dateNum: day,
      monthNum: month
    });
  }

  handlerGetStartTime(time, event){
    event.preventDefault();
    this.setState({
      startTimeCalendar: time
    });
  }

  handlerGetEndDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    this.setState({
      endDateCalendar: day + '.' + monthValue + '.' + year,
      iterFinishCalendar: iter
    });
  }

  handlerGetEndTime(time, event){
    event.preventDefault();
    this.setState({
      endTimeCalendar: time
    });
  }

  getNameNumberDay(weekday, num){
    const numberNameOne = [
      'перший',
      'другий',
      'третій',
      'чертвертий',
      'п\'ятий'
    ];
    const numberNameSecond = [
      'перша',
      'друга',
      'третя',
      'чертверта',
      'п\'ята'
    ];
    if(weekday === 1 || weekday === 2 || weekday === 4){
      return numberNameOne[num];
    } else {
      return numberNameSecond[num];
    }
  }

  getNthWeekdayOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekday = date.getDay();
    let nthWeekday = 0;

    for (let i = 1; i <= date.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      if (currentDate.getDay() === weekday) {
        nthWeekday++;
        if (currentDate.getTime() === date.getTime()) {
          return nthWeekday;
        }
      }
    }

    return null;
  }

  handlerChangDateInfo(status){
    this.setState(prevState => ({
      changeStatus: status
    }));
  }

  handlerGetEmoji(smile, event){
    this.setState({
      smileValue: smile
    });
  }

  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }

  render() {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.title = this.props.getTranslate('events-EditTitle') + ' | ' + this.props.companyName;
    }
    const events = [
      { value: null, label: this.props.getTranslate('events-RepeaNull')},
      { value: 'DAILY', label: this.props.getTranslate('events-RepeatDay') },
      { value: 'WEEKLY', label: this.props.getTranslate('events-RepeatWeek') + ' - ' + this.getNameDay(this.state.weeklyDay) },
      { value: 'MONTHLY', label: this.props.getTranslate('events-RepeatMonth') + ' - ' + this.getNameNumberDay(this.state.weeklyDay, this.state.nthWeekday) + ' ' + this.getNameDay(this.state.weeklyDay) },
      { value: 'YEAR', label: 'Кожного року' + ' - ' + this.state.dateNum + ' ' + this.getMonthsName(this.state.monthNum) },
      { value: 'WEEKLY2', label: 'Кожний будній день (з понеділка по п’ятницю)' },
    ];
    let chooseUsers = '';
    if(this.state.usersGet.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.usersGet.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}<span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span></span>
      })
    }
    let totalItem = this.state.dates.length * 7;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
            if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
              return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
            } else if(this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
              return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
            } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
              return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
            } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
              return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
            } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
              return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
            } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
              return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
            } else {
              return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
            }
        })}
      </tr>
    });
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let resultContent = '';
    if(this.state.error) {
      resultContent = <div className="errors-content">Ошибка...</div>;
    } else if(!this.state.isLoaded) {
      resultContent = <Loading />;
    } else {
      resultContent =             <div className="event-form-wrap">
              <form className="event-form" onSubmit={this.handlerEventSubmit.bind(this)}>
                <div className="event-form__inner">
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('events-Caption')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>При установке данной опции, меропритие будет отображаться у всех сотрудников и принять в нем участие смогут все желающие включая заранее добавленных участников.</p>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap__smile">
                      <input id="headline" className={`field-wrap__input field-wrap__input_width_75 ${this.state.errorFields && !this.state.headlineValue ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.headlineValue} onChange={this.handlerHeadlineValue.bind(this)} placeholder={this.props.getTranslate('events-CaptionFieldCaption')} />
                      {this.state.errorFields && !this.state.headlineValue ? <div className="validation-error">Введите заголовок</div> : ''}
                      <GetEmoji
                        tooltipValue={this.props.getTranslate('event-tooltipEmoji')}
                        onChangeHandler={this.handlerGetEmoji.bind(this)}
                        value={this.state.smileValue}
                      />
                  </div>
                  </div>
                  <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="description">{this.props.getTranslate('events-Description')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipEvent-DescriptionEvent')}</p>
                        </div>
                      </div>
                    </div>
                    {(typeof window !== "undefined" && typeof document !== 'undefined') ? <Editor
                      toolbar={{
                        options: ['inline', 'blockType', 'list'],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['bold', 'italic', 'underline']
                        },
                        blockType: {
                          inDropdown: true,
                          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ['unordered', 'ordered']
                        }
                      }}
                      wrapperClassName="wrapper-class editor-field"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class editor-toolbar"
                      editorState={this.state.contentState}
                      onEditorStateChange={this.onEditorStateChange}
                    /> : ''}
                    </div>
                    <div className="field-wrap">
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="link">{this.props.getTranslate('events-Link')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipEvent-LinkEvent')}</p>
                          </div>
                        </div>
                      </div>
                      <input id="link" className="field-wrap__input" type="text" value={this.state.linkValue} onChange={this.handlerLinkValue.bind(this)} />
                    </div>
                  <div className="event-form__row">
                    <div className="field-wrap">
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="place">{this.props.getTranslate('events-LocationName')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipEvent-LocationEvent')}</p>
                          </div>
                        </div>
                      </div>
                      <input id="place" className="field-wrap__input" type="text" value={this.state.placeValue} onChange={this.handlerPlaceValue.bind(this)} />
                    </div>
                    <div className="field-wrap">
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="address">{this.props.getTranslate('events-Location')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipEvent-AddressEvent')}</p>
                          </div>
                        </div>
                      </div>
                      <input id="address" className="field-wrap__input" type="text" value={this.state.addressValue} onChange={this.handlerAddressValue.bind(this)} />
                    </div>
                  </div>
                  <div className="event-form__row">
                    <CalendarDropdown
                      label={this.props.getTranslate('events-Date')}
                      translate={this.props.getTranslate}
                      handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                      handlerGetEndDate={this.handlerGetEndDate.bind(this)}
                      handlerGetStartTime={this.handlerGetStartTime.bind(this)}
                      handlerGetEndTime={this.handlerGetEndTime.bind(this)}
                      startDate={this.state.startDateCalendar}
                      startTime={this.state.startTimeCalendar}
                      endDate={this.state.endDateCalendar}
                      endTime={this.state.endTimeCalendar}
                      iterStartCalendar={this.state.iterStartCalendar}
                      iterFinishCalendar={this.state.iterFinishCalendar}
                      editCalendar={true}
                      handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                    />
                    <div className="field-wrap">
                      {this.state.repeatValue && this.state.changeStatus ? <div className="tooltip-reccuring-info">Зверніть увагу, при зміні дати може змінитись, день повторення події</div> : ''}
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Reccuring')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipEvent-ReccuringEvent')}</p>
                          </div>
                        </div>
                      </div>
                      {events ? <Select
                        styles={customStyles}
                        options={events}
                        value={this.state.repeatValue ? events[events.findIndex(i => i.value === this.state.repeatValue)] : events[0]}
                        placeholder={this.props.getTranslate('events-ReccuringPlaceholder')}
                        onChange={this.handlerRepeatEvent.bind(this)}
                      /> : ''}
                    </div>
                  </div>
                  <div className="event-form__row">
                    <div className="field-wrap">
                      <div className="field-wrap__label-wrap">
                        <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="bonuse-amount">{this.props.getTranslate('events-Bonuses')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipEvent-LocationEvent')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="field-wrap__currency-name">
                        <input id="bonuse-amount" className={`field-wrap__input ${this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} value={this.state.bonuseValue} onChange={this.handlerBonuseValue.bind(this)} placeholder={this.props.getTranslate('events-BonusesPlaceholder')} />
                        {this.props.currency ? <label className="field-wrap__currency-name-item" htmlFor="price">{this.props.currency}</label> : ''}
                      </div>
                      {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">В полі повинні бути тільки цифри</div> : ''}
                      {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">Не повинно перевищувати 999 999.99</div> : ''}
                    </div>
                    <div className="field-wrap field-wrap__tooltip field-wrap__tooltip-bonuses">
                      <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-2" type="checkbox" checked={this.state.bonuseConfirmation} onChange={this.handlerBonuseConfirmation.bind(this)} /><label htmlFor="styled-checkbox-2"></label></div><span>{this.props.getTranslate('events-Confirm')}</span></div>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p><p>{this.props.getTranslate('events-ConfirmEventTooltip')}</p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="event-form__row">
                    <div className="field-wrap field-wrap__members">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Members')}</label>
                      <div className="select-modal" onClick={this.handlerAddMembers.bind(this)}>
                      {this.state.usersGet.length < 1 ?
                      <div className="select-users__input">
                        {this.state.allUsers && this.state.usersGet.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                                  <use href={`${sprite}#close-icon`}></use>
                                                </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('getEmployees-FieldSelectPlaceholder')}</div>}
                      </div> : <div className="select-users__input">{chooseUsers}</div>}
                      </div>
                    </div>
                    <div className="tooltips-wrap tooltips-wrap__event field-wrap__tooltip-bonuses">
                      <div className="field-wrap field-wrap__tooltip">
                        <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" checked={this.state.open} onChange={this.handlerOpen.bind(this)} /><label htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('events-OpenStatus')}</span></div>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('events-OpenEventTooltip')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="edit-user-form__imgs">
                    <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('events-Photo')}</label>
                    <div className="edit-user-form__imgs-inner">
                      <div className="edit-user-upload">
                        <input type="file" id="upload" multiple onChange={this.handlerAddPhotos.bind(this)} />
                        <label htmlFor="upload">
                          <svg className="upload__icon">
                            <use href={`${sprite}#img-upload`}></use>
                          </svg>
                          {this.props.getTranslate('events-AddPhoto')} {this.state.formData && <span style={{marginTop: '5px'}}>{this.props.getTranslate('loadingImg-AmountTitle')} {this.state.formData.length}</span>}</label>
                      </div>
                      {this.state.formData && this.state.isLoadedSubmit ? <div className="edit-user-upload">
                        <div className="edit-user-upload__percent">
                          <span>{this.props.getTranslate('loadingImg-PercentTitle')} {this.state.percentValue}%</span>
                          <div className="edit-user-upload__bar">
                            <div className="edit-user-upload__bar-inner" style={{width: this.state.percentValue + '%'}}></div>
                          </div>
                        </div>
                      </div> : ''}
                      {this.state.photos ? <SortableList items={this.state.photos} onSortEnd={this.onSortEnd} axis={'xy'} handlerDelete={this.hanlderRemoveProductImage.bind(this)} /> : ''}
                    </div>
                  </div>
                </div>
                <div className="field-wrap__submit event-form__nav">
                  <button className="btn btn_size_lg" type="submit" disabled={this.state.isLoadedSubmit ? true : false}>{this.props.getTranslate('editEvent-buttonSave')}{this.state.isLoadedSubmit && <Loading />}</button>
                </div>
              </form>
            </div>
    }
    return <>
      <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
      {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink("/account/events")} replace={true} />) : ''}
      <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
      <div className="account-wrap">
        <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
        <div className="account-content">
          <div className="account-content__inner">

            {/* Account header component */}
            <AccountContentHeader
              handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
              languageActive={this.props.chooseLanguage}
              backBtn={true}
              backBtnType="link"
              backBtnClass="button-back_theme_gray"
              backBtnHandler={this.props.getPermalink("/account/events")}
              backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
            />

            {this.state.status === 'DELETED' ? <div className="team-nav">
              <div className="event-edit-status-deleted">
                <div className="event-edit-status-deleted__txt">{this.props.getTranslate('eventModal-StatusCancelled')}</div>
                <button className="event-edit-status-deleted__btn" type="button">{this.props.getTranslate('eventModal-RestoreButton')}</button>
              </div>
            </div> : ''}
            <div className="account-content__header-title">
              <h1 className="account-content__headline">{this.props.getTranslate('events-EditTitle')}</h1>
            </div>
            {resultContent}
          </div>
        </div>
      </div>
      {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="field-wrap field-wrap__search-members">
          <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
          <svg className="field-wrap__search-icon">
            <use href={`${sprite}#search-icon`}></use>
          </svg>
        </div>
        <div className="question-members">
          <div className="question-members-wgt">
            {users}
          </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
        </div>
      </div>
    </div> : ''}
    {this.state.dateModal && <div className="date-modal">
      <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
      <div className="date-modal__content">
        <div className="date-modal__header">
          <div className="date-modal__headline">{this.props.getTranslate('modal-calendarAddEventTitle')}</div>
          <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
            <svg className="date-modal__close-modal-icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="date-fields">
          <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
            <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishStartDate ? this.state.finishStartDate.replace(',', '') : ''} onKeyPress={this.onKeyPress.bind(this)} />
            <input className="date-field__time" type="text" maxLength="5" placeHolder="00:00" value={this.state.startTime} onChange={this.handlerStartTime.bind(this)} onKeyPress={this.onKeyPress2.bind(this)} />
          </div>
          <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
            <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishEndDate ? this.state.finishEndDate.replace(',', '') : ''} onKeyPress={this.onKeyPress.bind(this)} />
            <input className="date-field__time" type="text" maxLength="5" placeHolder="00:00" value={this.state.endTime} onChange={this.handlerEndTime.bind(this)} onKeyPress={this.onKeyPress2.bind(this)} />
          </div>
        </div>
        <div id="calendar" className="calendar">
          <div className="calendar__info">
            <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-left`}></use>
              </svg>
            </button>
            <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
            <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
              <svg className="calendar__month-nav-icon">
                <use href={`${sprite}#chevron-right`}></use>
              </svg>
            </button>
          </div>
          <table className="calendar-table">
            <thead className="calendar-table__head">
              <tr>
                <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
                <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
              </tr>
            </thead>
            <tbody className="calendar-table__dates">{tableDate}</tbody>
          </table>
        </div>
        <div className="form-nav__submit">
          <button className="btn btn_size_lg btn_width_100" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
        </div>
      </div>
    </div>}
    </>;
  }
}
export default (props) => <EventsEdit {...useParams()} {...props} />
