import React from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import sprite from '../media/icons.svg';
let times = [];
for (let hour = 0; hour < 24; hour++) {
  for (let minute = 0; minute <= 45; minute += 15) {
    let time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    times.push(time);
  }
}
function getFilteredTimeValues() {
  const now = new Date();
  const currentHour = now.getHours();
  const currentMinutes = now.getMinutes();

  return times.filter(time => {
    const [hour, minute] = time.split(':').map(Number);
    return hour > currentHour || (hour === currentHour && minute >= currentMinutes);
  });
}
function compareDate(inputDateStr) {
  // Разделяем строку на день, месяц и год
  const [day, month, year] = inputDateStr.split('.').map(Number);

  // Создаем объект Date для указанной даты
  const inputDate = new Date(year, month - 1, day); // Месяцы в JS начинаются с 0

  // Получаем текущую дату, обнулив часы, минуты и секунды
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  // Сравниваем даты
  if (inputDate > currentDate) {
    return true
  } else {
    return false;
  }
}
class CalendarDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusDropdownTime: false,
      typeDropdownTime: null,
      statusDropdownCalendar: false,
      typeDropdownCalendar: null,
      chooseDateFull: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: null,
      endTime: null,
      hoverDate: null,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      clickedInside: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  compareDates(date1, date2) {
    let [day1, month1, year1] = date1.split('.').map(Number);
    let [day2, month2, year2] = date2.split('.').map(Number);

    let firstDate = new Date(year1, month1 - 1, day1);
    let secondDate = new Date(year2, month2 - 1, day2);

    if (firstDate > secondDate) {
      return true;
    }
  }

  handlerClickInside(event){
    this.setState({
      statusDropdownTime: false,
      typeDropdownTime: null,
      statusDropdownCalendar: false,
      typeDropdownCalendar: null
    });
    this.props.handlerChangeDateForInfo(this.state.statusDropdownCalendar);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        statusDropdownTime: false,
        typeDropdownTime: null,
        statusDropdownCalendar: false,
        typeDropdownCalendar: null
      });
      this.props.handlerChangeDateForInfo(this.state.statusDropdownCalendar);
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setWrapperRefButton(node){
    this.wrapperRefButton = node;
  }

  handlerGetTime(type, event) {
    event.preventDefault();
    this.setState(prevState => ({
      statusDropdownTime: !prevState.statusDropdownTime,
      typeDropdownTime: type
    }));
  }
  handlerGetCalendar(type, event) {
    let date = null;
    event.preventDefault();
    if(this.props.editCalendar){
      if(type === 'start'){
        let dateArr = this.props.startDate.split('.').reverse();
        date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
      } else {
        let dateArr = this.props.endDate.split('.').reverse();
        date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
      }
      this.setState({
        date: date
      });
    }
    let fullYear = !this.props.editCalendar ? this.state.date.getFullYear() : date.getFullYear();
    let month = !this.props.editCalendar ? this.state.date.getMonth() : date.getMonth();
    let current = !this.props.editCalendar ? this.state.date.getDate() : date.getDate();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      month: month,
      statusDropdownCalendar: !prevState.statusDropdownCalendar,
      typeDropdownCalendar: type
    }));
    this.props.handlerChangeDateForInfo(!this.state.statusDropdownCalendar);
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      'Січень',
      'Лютий',
      'Березень',
      'Квітень',
      'Травень',
      'Червень',
      'Липень',
      'Серпень',
      'Вересень',
      'Жовтень',
      'Листопад',
      'Грудень'
    ];
    return monthes[num];
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1,
      iterStart: Number(prevState.countMonth) === (Number(prevState.countMonthSave) + 1) ? Number(prevState.iterStartSave) : '0'
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, 1);
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  render() {
    const monthes = [
      this.props.translate('modal-calendarMonthNameJan'),
      this.props.translate('modal-calendarMonthNameFeb'),
      this.props.translate('modal-calendarMonthNameMar'),
      this.props.translate('modal-calendarMonthNameApr'),
      this.props.translate('modal-calendarMonthNameMay'),
      this.props.translate('modal-calendarMonthNameJun'),
      this.props.translate('modal-calendarMonthNameJul'),
      this.props.translate('modal-calendarMonthNameAug'),
      this.props.translate('modal-calendarMonthNameSep'),
      this.props.translate('modal-calendarMonthNameOct'),
      this.props.translate('modal-calendarMonthNameNov'),
      this.props.translate('modal-calendarMonthNameDec')
    ];
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let iterFinish = 0;
    let tableDateStart = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          let dateValue = new Date(this.state.fullYear + '-' + (this.state.month + 1) + '-' + (elem + 1));
          let startValue = this.props.startDate.split('.').reverse().join('-');
          startValue = new Date(startValue);
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth() || this.state.date.getFullYear() < this.state.currentDate.getFullYear()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.props.iterStartCalendar === iter) {
            return <td className="checked" key={i}>{elem}</td>
          } else if(startValue.getDate() === elem && dateValue.getMonth() === startValue.getMonth() && dateValue.getFullYear() === startValue.getFullYear()) {
            return <td className="checked" key={i}>{elem}</td>
          } else if(!this.props.editCalendar && (this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth() || this.state.date.getFullYear() < this.state.currentDate.getFullYear()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(!this.props.editCalendar && this.state.currentDate >= dateValue && iter < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else {
            return <td key={i} onClick={this.props.handlerGetStartDate.bind(this, iter, elem, this.state.date.getMonth(), this.state.date.getFullYear(), iter > endedItems ? true : false, iter <= firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    let tableDateEnd = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iterFinish++;
          let dateValue = new Date(this.state.fullYear + '-' + (this.state.month + 1) + '-' + (elem + 1));
          let startValue = this.props.startDate.split('.').reverse().join('-');
          startValue = new Date(startValue);
          let finishValue = this.props.endDate.split('.').reverse().join('-');
          finishValue = new Date(finishValue);
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth() || this.state.date.getFullYear() < this.state.currentDate.getFullYear()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.props.iterFinishCalendar === iterFinish) {
            return <td className="checked" key={i}>{elem}</td>
          } else if(finishValue.getDate() === elem && dateValue.getMonth() === finishValue.getMonth() && dateValue.getFullYear() === finishValue.getFullYear()) {
           return <td className="checked" key={i}>{elem}</td>
         } else if(!this.props.editCalendar && (this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth() || this.state.date.getFullYear() < this.state.currentDate.getFullYear()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(!this.props.editCalendar && this.state.currentDate >= dateValue && iterFinish < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else {
            return <td key={i} onClick={this.props.handlerGetEndDate.bind(this, iterFinish, elem, this.state.date.getMonth(), this.state.date.getFullYear(), iterFinish > endedItems ? true : false, iterFinish <= firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    let valueStartDate = null;
    if(this.props.startDate){
      let arrStartDate = this.props.startDate.split('.');
      if(arrStartDate[0] < 10){
        arrStartDate[0] = '0' + arrStartDate[0];
      }
      if(arrStartDate[1] < 10){
        arrStartDate[1] = '0' + arrStartDate[1];
      }
      arrStartDate[2] = arrStartDate[2].toString().slice(-2);
      valueStartDate = arrStartDate.join('.');
    }
    let valueEndDate = null;
    if(this.props.endDate){
      let arrEndDate = this.props.endDate.split('.');
      if(arrEndDate[0] < 10){
        arrEndDate[0] = '0' + arrEndDate[0];
      }
      if(arrEndDate[1] < 10){
        arrEndDate[1] = '0' + arrEndDate[1];
      }
      arrEndDate[2] = arrEndDate[2].toString().slice(-2);
      valueEndDate = arrEndDate.join('.');
    }
    return <div className="field-wrap">
      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="date">{this.props.label}</label>
      <div className="calendar-field-row">
        <div className={`calendar-field__col${this.props.fullStartDate ? ' calendar-field__col_full' : ''}${this.props.offEndDate ? ' calendar-field__col-once' : ''}`}>
          <div className="calendar-field__input-calendar">
            <div className="calendar-field__input-calendar-inner">
              <div className={`calendar-field__value ${this.state.typeDropdownCalendar === 'start' ? 'active' : ''}`} onClick={this.handlerGetCalendar.bind(this, 'start')}>{valueStartDate}</div>
              {this.state.statusDropdownCalendar && this.state.typeDropdownCalendar === 'start' ? <div className="calendar calendar__dropdown" ref={this.setWrapperRef}>
                <div className="calendar__info">
                  <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
                    <svg className="calendar__month-nav-icon">
                      <use href={`${sprite}#chevron-left`}></use>
                    </svg>
                  </button>
                  <div className="calendar__month-year">{monthes[this.state.date.getMonth()]}, {this.state.date.getFullYear()}</div>
                  <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
                    <svg className="calendar__month-nav-icon">
                      <use href={`${sprite}#chevron-right`}></use>
                    </svg>
                  </button>
                </div>
                <table className="calendar-table" onClick={this.handlerClickInside.bind(this)}>
                  <thead className="calendar-table__head">
                    <tr>
                      <th>{this.props.translate('modal-calendarDayWeekMonday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekTuesday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekWednesday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekThursday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekFriday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekSutarday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekSunday')}</th>
                    </tr>
                  </thead>
                  <tbody className="calendar-table__dates">{tableDateStart}</tbody>
                </table>
              </div> : ''}
            </div>
          </div>
          <div className="calendar-field__input-time">
            <div className="calendar-field__input-time-inner">
              <div className={`calendar-field__value ${this.state.typeDropdownTime === 'start' ? 'active' : ''}`} onClick={this.handlerGetTime.bind(this, 'start')}>{this.props.startTime}</div>
              {this.state.statusDropdownTime && this.state.typeDropdownTime === 'start' ? <div className="calendar-field__time-content"  ref={this.setWrapperRef}>
              <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    renderView={props => <div {...props} className="view"/>}
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={350}
                      >
                {!compareDate(this.props.startDate) ? <ul className="calendar-field__list-time" onClick={this.handlerClickInside.bind(this)}>
                  {getFilteredTimeValues().map((item, index) => {
                    return <li key={index} onClick={this.props.handlerGetStartTime.bind(this, item)}>{item}</li>
                  })}
                </ul> : <ul className="calendar-field__list-time" onClick={this.handlerClickInside.bind(this)}>
                  {times.map((item, index) => {
                    return <li key={index} onClick={this.props.handlerGetStartTime.bind(this, item)}>{item}</li>
                  })}
                </ul>}
                </Scrollbars>
              </div> : ''}
            </div>
          </div>
        </div>
        {!this.props.offEndDate ? <div className="calendar-field__col">
        <div className="calendar-field__input-calendar">
          <div className="calendar-field__input-calendar-inner">
            <div className={`calendar-field__value ${this.state.typeDropdownCalendar === 'finish' ? 'active' : ''}`} onClick={this.handlerGetCalendar.bind(this, 'finish')}>{valueEndDate}</div>
            {this.state.statusDropdownCalendar && this.state.typeDropdownCalendar === 'finish' ? <div className="calendar calendar__dropdown" ref={this.setWrapperRef}>
              <div className="calendar__info">
                <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
                  <svg className="calendar__month-nav-icon">
                    <use href={`${sprite}#chevron-left`}></use>
                  </svg>
                </button>
                <div className="calendar__month-year">{monthes[this.state.date.getMonth()]}, {this.state.date.getFullYear()}</div>
                <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
                  <svg className="calendar__month-nav-icon">
                    <use href={`${sprite}#chevron-right`}></use>
                  </svg>
                </button>
              </div>
              <table className="calendar-table" onClick={this.handlerClickInside.bind(this)}>
                <thead className="calendar-table__head">
                  <tr>
                      <th>{this.props.translate('modal-calendarDayWeekMonday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekTuesday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekWednesday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekThursday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekFriday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekSutarday')}</th>
                      <th>{this.props.translate('modal-calendarDayWeekSunday')}</th>
                  </tr>
                </thead>
                <tbody className="calendar-table__dates">{tableDateEnd}</tbody>
              </table>
            </div> : ''}
          </div>
        </div>
          <div className="calendar-field__input-time">
            <div className="calendar-field__input-time-inner">
              <div className={`calendar-field__value ${this.state.typeDropdownTime === 'finish' ? 'active' : ''}`} onClick={this.handlerGetTime.bind(this, 'finish')}>{this.props.endTime}</div>
              {this.state.statusDropdownTime && this.state.typeDropdownTime === 'finish' ? <div className="calendar-field__time-content" ref={this.setWrapperRef}>
              <Scrollbars
                    renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                    renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                    renderView={props => <div {...props} className="view"/>}
                      autoHeight
                      autoHeightMin={0}
                      autoHeightMax={350}
                      >
                <ul className="calendar-field__list-time" onClick={this.handlerClickInside.bind(this)}>
                  {times.map((item, index) => {
                    return <li key={index} onClick={this.props.handlerGetEndTime.bind(this, item)}>{item}</li>
                  })}
                </ul>
                </Scrollbars>
              </div> : ''}
            </div>
          </div>
        </div> : ''}
      </div>
      {this.props.errors && !this.props.errors.every(element => element === '') ? (
        <>
          {this.props.errors.map((item, index) => (
            <div key={index} className="validation-error">{item}</div>
          ))}
        </>
      ) : ''}

    </div>
  }
}
export default CalendarDropdown;
