import React from "react";
import Loading from './Loading';
import { Scrollbars } from 'react-custom-scrollbars-2';
import member_1 from '../media/avatar-1.png';
import sprite from '../media/icons.svg';
import { getAuth, onAuthStateChanged } from "firebase/auth";
class ModalAddUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDisabled: false,
      users: null,
      dataUsers: null,
      usersIDs: [],
      searchValue: '',
      allUsers: false
    }
  }
  componentDidMount() {
    let that = this;
    if(this.props.dataUsers){
      this.setState({
        dataUsers: this.props.dataUsers
      });
    }
    if(this.props.getCompanyUsers){
      getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/users/search`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(
            {
              "itemsPerPage": 20
            }
          )
        }).then(res => {
          if(res.status === 200) {
            res.json().then(function(data) {
              that.setState({
                users: data.data
              });
            });
            const valuesArray = that.props.dataUsers.map(obj => obj.userID);
              that.setState({
                usersIDs: valuesArray
              });
          } else if(res.status === 401) {
            res.json().then(function(data) {

            });
          } else {
            res.json().then(function(data) {

            });
          }
        })
      })
    } else {
      that.setState({
        users: this.props.dataInvitations,
        usersForSearch: this.props.dataInvitations
      });
    }
  }
  handlerSearch(event){
    let that = this;
    this.setState({
      searchValue: event.target.value
    })
    setTimeout(() => {
      if(this.props.type !== 'event-bonuses'){
        getAuth().currentUser.getIdToken().then(function(idToken){
          const myHeaders = new Headers({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + idToken
          });
          fetch(`${that.props.queryLink}/api/v1/users/search`, {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify({"itemsPerPage": 20, "query": that.state.searchValue})
          }).then(res => {
            if(res.status === 200) {
              res.json().then(function(data) {
                that.setState({
                  users: data.data
                });
              });
              const valuesArray = that.props.dataUsers.map(obj => obj.userID);
                that.setState({
                  usersIDs: valuesArray
                });
            } else if(res.status === 401) {
              res.json().then(function(data) {

              });
            } else {
              res.json().then(function(data) {

              });
            }
          })
        })
      } else {
        const searchTerm = event.target.value.toLowerCase();
        const filteredUsers = that.state.usersForSearch.filter(function(user) {
          return user.userName.toLowerCase().includes(searchTerm);
        });
        that.setState({ searchValue: event.target.value, filteredUsers, users: filteredUsers});
      }
    }, 1000);
  }
  handlerToggleUser(id, event){
    event.preventDefault();
    const { usersIDs } = this.state;
    this.setState(prevState => ({
      allUsers: false
    }));
    if (usersIDs.includes(id)) {
      this.setState({ usersIDs: usersIDs.filter((i) => i !== id) });
    } else {
      this.setState({ usersIDs: [...usersIDs, id] });
    }
  }
  handlerToggleAllUsers(event){
    let users = this.state.usersIDs;
    this.setState(prevState => ({
      allUsers: !prevState.allUsers
    }));
    if(this.props.type !== 'event-bonuses'){
      if(this.state.allUsers){
        this.setState(prevState => ({
          usersIDs: []
        }))
      } else {
        this.setState({
          usersIDs: users
        })
      }
    } else {
      if(!this.state.allUsers){
        let users = this.state.users.map(item => item.userID);
        if(users){
          this.setState(prevState => ({
            usersIDs: users
          }))
        }
      } else {
        this.setState(prevState => ({
          usersIDs: []
        }))
      }
    }
  }
  handlerClose(event){
    this.props.handlerClose();
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modalDisabled: false
      }));
    }, 500);
  }
  render() {
    return <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
      <div className="modal-background" onClick={this.handlerClose.bind(this)}></div>
      <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">{this.props.title}</h2>
          <button className="close-modal" type="button" onClick={this.handlerClose.bind(this)}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="field-wrap field-wrap__search-members">
          <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearch.bind(this)} placeholder={this.props.translation('modal-addUsersSearchPlaceholder')} />
          <svg className="field-wrap__search-icon">
            <use href={`${sprite}#search-icon`}></use>
          </svg>
        </div>
        <div className="question-members">
        {this.state.users ? <>
          <div className="question-members-wgt">
            <div className="team-list-modal">
            <Scrollbars
                  renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
                  renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                  renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                  renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                  renderView={props => <div {...props} className="view"/>}
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={500}
                    >
              <div className={`users-modal__item users-modal__item-wrap ${this.state.allUsers ? 'active' : ''}`} onClick={this.handlerToggleAllUsers.bind(this)}>
                <div className="users-modal__info">
                  <div className="users-modal__avatar-icon-wrap">
                    <svg className="users-modal__avatar-icon">
                      <use href={`${sprite}#all-team-icon`}></use>
                    </svg>
                  </div>
                  <span className="users-modal__name">{this.props.translation('modalAddUser-AllUsersLabel')}</span>
                </div>
                {this.state.allUsers ? <div className="users-modal__check-wrap">
                  <svg className="users-modal__check">
                    <use href={`${sprite}#check-icon`}></use>
                  </svg>
                </div> : ''}
              </div>
              {this.state.users.map((item, index) => {
                let userExists = false;
                let iconStatus = '';
                if(this.state.dataUsers && this.props.getCompanyUsers){
                  userExists = this.state.dataUsers.some(user => user.userID === item.userID);
                }
                if(this.props.type === 'event-bonuses'){
                  if(item.invitationStatus === 'ACCEPTED'){
                    iconStatus = <div className="users-modal__check-wrap"><svg className="users-modal__check"><use href={`${sprite}#check-icon`}></use></svg></div>;
                  } else if(item.invitationStatus === 'REJECTED') {
                    iconStatus = <svg className="users-modal__check"><use href={`${sprite}#status-modal-rejected`}></use></svg>;
                  } else if(item.invitationStatus === 'WAITING'){
                    iconStatus = <svg className="users-modal__check"><use href={`${sprite}#status-modal-waiting`}></use></svg>;
                  } else {
                    iconStatus = <svg className="users-modal__check"><use href={`${sprite}#status-modal-dont`}></use></svg>;
                  }
                }
                return <div className={`users-modal__item users-modal__item-wrap ${this.state.allUsers ? 'active' : ''} ${userExists && !this.props.getCompanyUsers ? 'active' : ''} ${this.state.usersIDs.indexOf(item.userID) !== -1 ? 'active' : ''}`} key={index} onClick={this.handlerToggleUser.bind(this, item.userID)}>
                     <div className="users-modal__info">
                       <img className="users-modal__avatar" src={item.photo ? item.photo : item.userPhoto} alt="" />
                       <div className="users-modal__name">{item.name ? item.name : item.userName}{item.lastName ? ' ' + item.lastName : ''}</div>
                     </div>
                     {(this.props.getCompanyUsers && this.state.usersIDs.indexOf(item.userID) !== -1) || (this.state.allUsers && this.props.type !== 'event-bonuses') ? <div className="users-modal__check-wrap">
                       <svg className="users-modal__check">
                         <use href={`${sprite}#check-icon`}></use>
                       </svg>
                     </div> : ''}
                       {iconStatus}
                   </div>
              })}
              </Scrollbars>
            </div>
          </div>
            <div className="field-wrap__submit">
              <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" disabled={this.props.submitButtonLoading ? true : false} onClick={this.props.handlerSubmit.bind(this, this.state.usersIDs, this.state.allUsers)}>{this.props.submitButtonTitle}{this.props.submitButtonLoading ? <Loading /> : ''}</button>
            </div></> : <Loading />}
          </div>
      </div>
    </div>
  }
}
export default ModalAddUsers;
