import React from "react";
import Select from 'react-select';
import sprite from '../media/icons.svg';
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
}
const generateOptions = () =>
  Array.from({ length: 31 }, (_, i) => {
    const value = (i + 1).toString();
    return { label: value, value: value };
  });
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 100;
    return Array.from({ length: currentYear - startYear + 1 }, (_, i) => {
      const year = currentYear - i; // Считаем от текущего года назад
      return { label: year.toString(), value: year.toString() };
    });
  };
class GetDateDropdownModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalDisabled: false,
      dayValue: '',
      monthValue: '',
      yearValue: ''
    }
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined'){
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true,
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalDisabled: false
      }));
    }, 500);
  }
  handleModal (event) {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  handleGetDay(event){
    this.setState({
      dayValue: event.value
    });
  }
  handleGetMonth(event){
    this.setState({
      monthValue: event.value
    });
  }
  handleGetYear(event){
    this.setState({
      yearValue: event.value
    });
  }
  render() {
    let monthes = [
          {label: 'Січень', value: '0'},
          {label: 'Лютий', value: '1'},
          {label: 'Березень', value: '2'},
          {label: 'Квітень', value: '3'},
          {label: 'Травень', value: '4'},
          {label: 'Червень', value: '5'},
          {label: 'Липень', value: '6'},
          {label: 'Серпень', value: '7'},
          {label: 'Вересень', value: '8'},
          {label: 'Жовтень', value: '9'},
          {label: 'Листопад', value: '10'},
          {label: 'Грудень', value: '11'}
        ];
    return <>
      <div className={`user-statistic-table__add-btn`} onClick={this.handleModal.bind(this)}>{this.props.btnName}</div>
      {this.state.modal ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled modal-container` : `two modal-container`}>
      <div className="modal-background" onClick={this.handleModalDisabled}></div>
        <div className="modal">
        <div className="modal-header">
          <h2 className="modal-headline">Дата народження</h2>
          <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
            <svg className="close-modal__icon">
              <use href={`${sprite}#close-icon`}></use>
            </svg>
          </button>
        </div>
        <div className="modal-content">
          <div className="modal-date-select">
            <label className="field-wrap__label field-wrap__label_theme_gray">Виберіть дату</label>
            <div className="modal-date-select__inner">
            <div className="modal-date-select__field">
                <Select
                  styles={this.props.errors.length > 0 ? customStylesError : customStyles}
                  options={generateOptions()}
                  placeholder={"День"}
                  onChange={this.handleGetDay.bind(this)}
                />
              </div>
              <div className="modal-date-select__field">
                <Select
                  styles={this.props.errors.length > 0 ? customStylesError : customStyles}
                  options={monthes}
                  placeholder={"Місяць"}
                  onChange={this.handleGetMonth.bind(this)}
                />
              </div>
              <div className="modal-date-select__field">
                <Select
                  styles={this.props.errors.length > 0 ? customStylesError : customStyles}
                  options={generateYearOptions()}
                  placeholder={"Рік"}
                  onChange={this.handleGetYear.bind(this)}
                />
              </div>
            </div>
            {this.props.errors && this.props.errors.length > 0 ?
              <>
                {this.props.errors.map((item, index) => {
                  return <div className="validation-error validation-error__center validation-error__option" key={index}>{item}</div>
                })}
              </>
              : ''}
          </div>
          <div className="field-wrap__submit">
            <button className="btn btn_width_100 btn_size_lg" type="button" onClick={this.props.handlerSubmitDate.bind(this, (this.state.yearValue + '-' +  this.state.monthValue + '-' + this.state.dayValue), this.props.type)}>Зберегти</button>
          </div>
        </div>
        </div>
      </div> : ''}
    </>
  }
}
export default GetDateDropdownModal;
