import React from "react";
import GetEmoji from './components/GetEmoji';
import DateFormating from './components/DateFormating';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AccountHeader from './components/AccountHeader';
import AccountContentHeader from './components/AccountContentHeader';
import AccountNav from './components/AccountNav';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Loading from './components/Loading';
import { Link, Navigate } from "react-router-dom";
import ResultErrors from './components/ResultErrors';
import Select from 'react-select';
import CalendarDropdownOnceTime from './components/CalendarDropdownOnceTime';
import img_preview from './media/item-preview-quick-survey.png';
import sprite from './media/icons.svg';
import member_1 from './media/avatar-1.png';
import smile from './media/emoji-icon.svg';
import { getAuth } from "firebase/auth";
let arrayMoveImmutable;
if (typeof window !== 'undefined') {
  // Выполняем импорт только в клиентской среде
  const { arrayMoveImmutable: clientArrayMoveImmutable } = require('array-move');
  arrayMoveImmutable = clientArrayMoveImmutable;
}
const customStyles = {
  control: () => ({
    height: '42px',
    border: '1px solid #e1e1e1',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
};
const customStylesError = {
  control: () => ({
    height: '42px',
    border: '1px solid #ff0000',
    borderRadius: '5px',
    display: 'flex'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: '#000'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: '#e8ecff'
  }),
  multiValueLabel: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  }),
  multiValueRemove: (provided, state) => ({
  ...provided,
  color: '#364ed4',
  ':hover': {
    backgroundColor: '#e8ecff',
    color: '#364ed4',
  },
})
};
const option = {
      "answerType": "SINGLE",
      "options": [
        {
          "answerOptionID": 0,
          "answerOptionType": "",
          "name": "",
          "priority": 0,
          "userAnswers": [
            {
              "answer": "string",
              "optionID": 0,
              "userID": 0,
              "userName": "string"
            }
          ]
        }
      ],
      "priority": 0,
      "question": "",
      "questionID": 0
    };
    const DragHandle = SortableHandle(() => <button className="question-wgt-item__nav-btn" type="button">
      <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
        <use href={`${sprite}#lines-icon`}></use>
      </svg>
    </button>);
    const SortableItem = SortableElement(({items, item, value, handlerOnChange, handlerOptionType, removeQuestion, choosePoint, error, getTranslate}) => <div className="quick-question-points__point" key={item}>
                                                                          <div className="field-wrap quick-question-points__field">
                                                                            <input className={`field-wrap__input ${error && !value.question ? 'field-wrap__input_error' : ''}`} type="text" value={value.question} onChange={handlerOnChange.bind(this, item)} placeholder={getTranslate('polls-QuestionValuePlaceholder')} />
                                                                            {error && !value.question && value.options[0].answerOptionType ? <div className="validation-error">{getTranslate('polls-QuestionValueError')}</div> : ''}
                                                                            {error && (!value.question && !value.options[0].answerOptionType) ? <div className="validation-error">{getTranslate('polls-QuestionOptionTypeError')}</div> : ''}
                                                                          </div>
                                                                          <div className="quick-question-points__nav">
                                                                            <div className="field-wrap">
                                                                              <Select
                                                                                styles={error && !value.options[0].answerOptionType ? customStylesError : customStyles}
                                                                                options={[
                                                                                    { value: 'RATE', label: getTranslate('polls-QuestionPointLabelRate') },
                                                                                    { value: 'YES_NO', label: getTranslate('polls-QuestionPointLabelYesNo') },
                                                                                    { value: 'YES_NO_DONT_KNOW', label: getTranslate('polls-QuestionPointLabelYesNoDontKnow') },
                                                                                    { value: 'FREE', label: getTranslate('polls-QuestionPointLabelFree') }
                                                                                  ]}
                                                                                onChange={handlerOptionType.bind(this, item)}
                                                                                value={value.options[0].answerOptionType ? {value: value.options[0].answerOptionType, label: value.options[0].name} : null}
                                                                                placeholder={getTranslate('select-selectPlaceholder')}
                                                                                isSearchable={false}
                                                                              />
                                                                              {error && !value.options[0].answerOptionType ? <div className="validation-error">{getTranslate('polls-QuestionTypeError')}</div> : ''}
                                                                            </div>
                                                                            <div className="question-form__points-item-nav">
                                                                              <button className="question-wgt-item__nav-btn" type="button" onClick={removeQuestion.bind(this, item)}>
                                                                                <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_close">
                                                                                  <use href={`${sprite}#close-icon`}></use>
                                                                                </svg>
                                                                              </button>
                                                                              <DragHandle />
                                                                            </div>
                                                                          </div>
                                                                        </div>);
    const SortableList = SortableContainer(({items, handlerOnChange, handlerOptionType, removeQuestion, choosePoint, error, getTranslate}) => {
      return (
        <div>
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} items={items} item={index} index={index} value={value} handlerOnChange={handlerOnChange} handlerOptionType={handlerOptionType} removeQuestion={removeQuestion} choosePoint={choosePoint} error={error} getTranslate={getTranslate} />
          ))}
        </div>
      );
    });
class SurveysCreateQuick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuStart: true,
      mobileMenu: false,
      dropdownStatus: false,
      getAllUsers: false,
      allUsers: false,
      getUsersIds: [],
      usersIds: [],
      isLoadedSubmitAddSurvey: false,
      dateModal: false,
      date: new Date(),
      fullYear: null,
      month: null,
      currentDate: new Date(),
      dates: [],
      countFirstElems: null,
      countEndedElems: null,
      pushElemsTotal: null,
      startDate: null,
      endDate:null,
      finishStartDate: null,
      finishEndDate: null,
      startTime: '09:00:00',
      endTime: '23:59:59',
      hoverDate: null,
      recurring: null,
      addQuestion: false,
      modalDisabled: false,
      modal: false,
      modalAddMembers: false,
      chooseMembers: [],
      chooseMembers2: [],
      memberItems: '',
      chooseDateFull: null,
      pointsElem: [1],
      pointsLength: false,
      surveyName: (typeof window !== "undefined" && typeof document !== 'undefined') ? (localStorage.getItem("surveyName") ? localStorage.getItem("surveyName") : '') : '',
      surveyBonus: '',
      surveyDescription: '',
      chooseAllMembers: false,
      chooseMembersIds: [],
      users: [],
      errorUser: null,
      isLoadedUser: false,
      anonymousSurvey: false,
      options: [option],
      totalUsers: null,
      addDescription: false,
      answerType: 'SINGLE',
      optionsDefault: [],
      errorFields: false,
      errorQuestions: false,
      searchValue: '',
      usersSearch: [],
      chooseDateType: 'start',
      errors: null,
      currencyCompany: (typeof window !== "undefined" && typeof document !== 'undefined') ? localStorage.getItem('currencyMt') : '',
      smileValue: null,
      getSmileModal: false,
      iterStart: null,
      iterStartSave: null,
      iterHover: null,
      countMonth: 1,
      countMonthSave: null,
      iterFinish: null,
      countMonthFinish: null,
      previewStatus: false,
      objForSubmit: {},
      modalDeleteEvents: false,
      startDateCalendar: null,
      endDateCalendar: null,
      startTimeCalendar: '00:00',
      endTimeCalendar: '22:00',
      iterStartCalendar: null,
      iterFinishCalendar: null,
      weeklyDay: null,
      nthWeekday: null,
      dateNum: null,
      monthNum: null,
      getUsersIdsHover: [],
      errorNameOnlySpace: false,
      errorDescriptionOnlySpace: false,
                      errorDiffDate: false,
            errorDiffMinutesDate: false,
            surveyNameLength: false,
            errorDescriptionLimit: false
    }
  }
  getNameDay(num, days){
    return days[num];
  }
  getMonthsName(num){
    const monthes = [
      'січня',
      'лютого',
      'березня',
      'квітня',
      'травня',
      'червня',
      'липня',
      'серпня',
      'вересня',
      'жовтня',
      'листопада',
      'грудня'
    ];
    return monthes[num];
  }
  handlerDeleteEventModal(event){
    this.setState({
      modalDeleteEvents: true
    });
  }
  componentDidMount() {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      if(document.body.classList.contains('preview')){
        document.body.classList.remove('preview');
      }
    }
    let nowDate = new Date();
    let nowDateFull = nowDate.getDate() + '.' + (nowDate.getMonth() + 1) + '.' + nowDate.getFullYear();
    let weeklyDay = nowDate.getDay();
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate()));
    this.setState( {
      startDateCalendar: nowDateFull,
      endDateCalendar: nowDateFull,
      weeklyDay: weeklyDay,
      nthWeekday: nthWeekday - 1,
      dateNum: nowDate.getDate(),
      monthNum: nowDate.getMonth()
    } );
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      const div = document.querySelector( '.field-wrap-smile');
      document.addEventListener('click', e => {
        const withinBoundaries = e.composedPath().includes(div);
        if ( ! withinBoundaries ) {
          this.setState( {
            getSmileModal: false
          } );
        }
      });
    }
    const option = {
          "answerType": "SINGLE",
          "options": [
            {
              "answerOptionID": 0,
              "answerOptionType": "",
              "name": "",
              "priority": 0,
              "userAnswers": [
                {
                  "answer": "string",
                  "optionID": 0,
                  "userID": 0,
                  "userName": "string"
                }
              ]
            }
          ],
          "priority": 0,
          "question": "",
          "questionID": 0
        };
    this.setState(prevState => ({
      options: [option]
    }));
  }
  onSortEnd = ({oldIndex, newIndex}) => {
    let options = arrayMoveImmutable(this.state.options, oldIndex, newIndex);
    options.map((item, index) => {
      item.priority = index
    });
    this.setState(prevState => ({
      options: options
    }));
  }
  handlerMobileMenu = (mobileMenu) => {
    this.setState( {mobileMenuStart: false} )
    this.setState( {mobileMenu} )
  };
  handlerDropdown = (event) => {
    this.setState({
      dropdownStatus: !this.state.dropdownStatus
    });
  }
  handlerCloseError = () => {
    this.setState(prevState => ({
      errors: null
    }));
  }
  handlerAddMembers (event) {
    event.preventDefault();
    let that = this;
    if(event.target.tagName !== 'SPAN'){
      this.setState({
        modal: true,
        modalAddMembers: true,
        users: [],
        isLoadedUser: false,
        errorUsers: null
      });
      getAuth().currentUser.getIdToken().then(function(idToken){
      const myHeaders = new Headers({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + idToken
      });
      fetch(`${that.props.queryLink}/api/v1/teams/search/users`, {
        method: 'GET',
        headers: myHeaders
      }).then(res => {
        if(res.status !== 200) {
          res.json().then(function(data) {
            that.setState({
              errors: data.errors,
              isLoadedUser: true,
              errorUsers: data.errors
            })
          });
        } else {
          res.json().then(function(data) {
            that.setState({
              isLoadedUser: true,
              users: data
            })
          });
        }
      })
    })
    }
  }
  handlerGetDate (event) {
    event.preventDefault();
    let fullYear = this.state.date.getFullYear();
    let month = this.state.date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      dateModal: !prevState.dateModal,
      dates: arr,
      fullYear: fullYear,
      pushElemsTotal: pushElemsNum,
      month: month
    }));
  }
  getLastDayOfMonth (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
  }
  getUnshiftElemsNum (year, month) {
    let jsDayNum = this.getFirstWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return realDayNum - 1;
  }
  getPushElemsNum (year, month) {
    let jsDayNum = this.getLastWeekDayOfMonthNum(year, month);
    let realDayNum = this.getRealDayOfWeekNum(jsDayNum);
    return 7 - realDayNum;
  }
  createArr (from, to) {
    const arr = [];
    for(let i = from; i <= to; i++) {
      arr.push(i);
    }
    return arr;
  }
  unshiftElems (num, lastDayPrevMonth, arr) {
    this.setState(prevState => ({
      countFirstElems: num
    }));
    let count =  lastDayPrevMonth - num;
    for(let i = lastDayPrevMonth; i > count; i--) {
      arr.unshift(i);
    }
    return arr;
  }
  pushElems (num, arr) {
    this.setState(prevState => ({
      countEndedElems: num
    }));
    for(let i = 1; i <= num; i++) {
      arr.push(i);
    }
    return arr;
  }
  chunkArr (num, arr) {
    let result = [];
    let chunk = [];
    let iterCount = arr.length / num;
    for(let i = 0; i <iterCount; i++) {
      chunk = arr.splice(0, num);
      result.push(chunk);
    }
    return result;
  }
  getRealDayOfWeekNum (jsNumOfDay) {
    if(jsNumOfDay === 0){
      return 7;
    } else {
      return jsNumOfDay;
    }
  }
  getFirstWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month, 1);
    return date.getDay();
  }
  getLastWeekDayOfMonthNum (year, month) {
    const date = new Date(year, month + 1, 0);
    return date.getDay();
  }
  getMonthName (num) {
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec')
    ];
    return monthes[num];
  }
  handlerCloseGetDate (event) {
    event.preventDefault();
    this.setState(prevState => ({
      dateModal: false
    }));
  }
  handlerPrevMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() - 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) - 1,
      iterStart: Number(prevState.countMonth) === (Number(prevState.countMonthSave) + 1) ? Number(prevState.iterStartSave) : '0'
    }));
  }
  handlerNextMonth (event) {
    event.preventDefault();
    let date = new Date(this.state.date.getFullYear(), this.state.date.getMonth() + 1, this.state.date.getDate());
    let fullYear = date.getFullYear();
    let month = date.getMonth();
    let arr = [];
    let firstDateOfMonth = 1;
    let lastDateOfMonth = this.getLastDayOfMonth(fullYear, month);
    let unshiftElemsNum = this.getUnshiftElemsNum(fullYear, month);
    let pushElemsNum = this.getPushElemsNum(fullYear, month);
    arr = this.createArr(firstDateOfMonth, lastDateOfMonth);
    arr = this.unshiftElems(unshiftElemsNum, new Date(fullYear, month, 0).getDate(), arr);
    arr = this.pushElems(pushElemsNum, arr);
    arr = this.chunkArr(7, arr);
    this.setState(prevState => ({
      date: date,
      dates: arr,
      fullYear: fullYear,
      month: month,
      countMonth: Number(prevState.countMonth) + 1,
      iterStart: Number(prevState.countMonth) >= Number(prevState.countMonthSave) ? '0' : Number(prevState.iterStartSave)
    }));
  }
  chooseDate (iterStart, nextMonth, prevMonth, event) {
    event.preventDefault();
    let month = Number(this.state.month) + 1;
    let year = this.state.fullYear;
    if(nextMonth){
      month = Number(month) + 1;
      if(month === 13){
        month = 1;
        year = year + 1;
      }
    }
    if(prevMonth){
      month = Number(month) - 1;
      if(month === 0){
        month = 12;
        year = year - 1;
      }
    }
    if(!this.state.finishStartDate){
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth
      }));
    } else if(this.state.chooseDateType === 'start') {
      this.setState(prevState => ({
        chooseDateType: 'finish',
        startDate: year + '-' + month + '-' + event.target.innerHTML,
        finishStartDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterStart: iterStart,
        iterStartSave: iterStart,
        countMonthSave: prevState.countMonth
      }));
    } else if(this.state.chooseDateType === 'finish'){
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth
      }));
    } else {
      this.setState(prevState => ({
        endDate: new Date(prevState.startDate) > new Date(year + '-' + month + '-' + event.target.innerHTML) ? null : year + '-' + month + '-' + event.target.innerHTML,
        finishEndDate: (event.target.innerHTML < 10 ? ('0' + event.target.innerHTML) : event.target.innerHTML) + '.' + (month < 10 ? '0' + month : month) + '.' + year,
        iterFinish: iterStart,
        countMonthFinish: prevState.countMonth
      }));
    }
  }
  chooseDateHover (iterHover, event) {
    event.preventDefault();
    if(this.state.startDate){
      this.setState(prevState => ({
        iterHover: iterHover
      }));
    }
  }
  chooseEndHover (event) {
    event.preventDefault();
    this.setState(prevState => ({
      iterHover: null
    }));
  }
  onKeyPress (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += '.';
    }
    if(len === 5) {
      input.value += '.';
    }
  }
  onKeyPress2 (event) {
    let input = event.target;
    if(event.charCode < 47 || event.charCode > 57) {
      event.preventDefault();
    }
    var len = input.value.length;
    if(len !== 1 || len !== 3) {
      if(event.charCode == 47) {
        event.preventDefault();
      }
    }
    if(len === 2) {
      input.value += ':';
    }
  }
  handlerAddQuestion (event) {
    event.preventDefault();
    let options =     {
      "answerType": "SINGLE",
      "options": [
        {
          "answerOptionID": 0,
          "answerOptionType": "CHECKBOX",
          "name": "",
          "priority": 0,
          "userAnswers": [
            {
              "answer": "string",
              "optionID": 0,
              "userID": 0,
              "userName": "string"
            }
          ]
        }
      ],
      "priority": this.state.optionsDefault.length,
      "question": "",
      "questionID": 0
    };
    this.setState(prevState => ({
      addQuestion: true,
      optionsDefault: [...prevState.optionsDefault, options]
    }));
  }
  handleModalDisabled = () => {
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.querySelector('body').style.overflow = "";
    }
    this.setState(prevState => ({
      modalDisabled: true
    }));
    setTimeout(() => {
      this.setState(prevState => ({
        modal: false,
        modalAddMembers: false,
        modalDisabled: false,
        modalData: {},
        modalDeleteEvents: false,
                      searchValue: '',
      usersSearch: []
      }));
    }, 500);
  }
  handlerChooseMember (member, id, event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    let arrIds = this.state.chooseMembersIds;
    let index = arr.indexOf(member);
    let indexIds = arrIds.indexOf(id);
    if(index !== -1){
      arr.splice(index, 1);
    } else {
      arr.push(member);
    }
    if(indexIds !== -1){
      arrIds.splice(indexIds, 1);
    } else {
      arrIds.push(id);
    }
    this.setState(prevState => ({
      chooseMembers: arr,
      chooseMembersIds: arrIds
    }));
  }
  setChooseMembers (event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    let result = arr.map((item, index) => {
      return <div className="select-users__label" key={index} onClick={this.removeMember.bind(this, index)}>{item}<span><svg className="select-users__remove">
                                <use href={`${sprite}#close-icon`}></use>
                              </svg></span></div>
    });
    this.setState(prevState => ({
      memberItems: result
    }));
    this.handleModalDisabled();
  }

  removeMember (index, event) {
    event.preventDefault();
    let arr = this.state.chooseMembers;
    arr.splice(index, 1);
    let result = arr.map((item, index) => {
      return <div className="select-modal__item" key={index} onClick={this.removeMember.bind(this, index)}>{item}<span>&#x2716;</span></div>
    });
    this.setState(prevState => ({
      memberItems: result
    }));
  }
  chooseAllMembers (event) {
    event.preventDefault();
    var total = 0;
    var finalArr = this.state.users.reduce((m, o) => {
      if(o.users) {
        total = total + o.users.length;
      }
      return total;
    }, []);
    this.setState(prevState => ({
      chooseAllMembers: !prevState.chooseAllMembers,
      chooseMembers: ['Иванов И. В.', 'Петров И. В.', 'Алексеев И. Г.'],
      totalUsers: total
    }));
  }
  handlerStartTime (event) {
    event.preventDefault();
    this.setState({
      startTime: event.target.value
    });
  }
  handlerEndTime (event) {
    event.preventDefault();
    this.setState({
      endTime: event.target.value
    });
  }
  setDateInput (event) {
    event.preventDefault();
    let re = /-/gi;
    this.setState(prevState => ({
      chooseDateFull: prevState.finishStartDate.split("-").reverse().join(".") + ' - ' + (prevState.finishEndDate ? prevState.finishEndDate.split("-").reverse().join(".") : prevState.finishStartDate.split("-").reverse().join(".")),
      endTime: '23:59:59',
      dateModal: !prevState.dateModal
    }));
  }
  handlerStartTimeSelect(event){
    this.setState({
      startTime: event.value
    });
  }
  handlerAddPoint (event) {
    event.preventDefault();
    let arr = this.state.options;
    if(arr.length < 4){
      const newArray = [ ...arr, {
      "answerType": "SINGLE",
      "options": [
        {
          "answerOptionID": 0,
          "answerOptionType": "",
          "name": "",
          "priority": 0,
          "userAnswers": [
            {
              "answer": "string",
              "optionID": 0,
              "userID": 0,
              "userName": "string"
            }
          ]
        }
      ],
      "priority": arr.length,
      "question": "",
      "questionID": 0
    } ];
      this.setState(prevState => ({
        options: newArray
      }));
    } else {
      this.setState(prevState => ({
        pointsLength: true
      }));
    }
  }
  handlerSurveyName (event) {
    if(this.state.errorFields){
      this.setState({
        errorFields: false
      });
    }
    if(event.target.value.length <= 250){
      this.setState({
        surveyName: event.target.value,
        surveyNameLength: false
      });
    } else {
      this.setState({
        surveyNameLength: true
      });
    }

  }
  handlerSurveyBonus(event) {
      const inputValue = event.target.value;
      if (inputValue === "") {
          this.setState({
              surveyBonus: "",
              bonuseValueNumError: false,
              bonuseValueCountError: false
          });
          return;
      }
      if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
          const floatValue = parseFloat(inputValue);
          if (!isNaN(floatValue)) {
              if (floatValue > 999999.99) {
                  this.setState({
                      bonuseValueCountError: true,
                      bonuseValueNumError: false
                  });
              } else {
                  this.setState({
                      surveyBonus: inputValue,
                      bonuseValueNumError: false,
                      bonuseValueCountError: false
                  });
              }
          } else {
              this.setState({
                  bonuseValueCountError: false,
                  bonuseValueNumError: true
              });
          }
      } else {
        const floatValue = parseFloat(inputValue);
        if (!isNaN(floatValue)) {
          this.setState({
              bonuseValueCountError: true
          });
        } else {
          this.setState({
              bonuseValueNumError: true
          });
        }
      }
  }
handlerSurveyDescription(event) {
  const value = event.target.value;
  if (this.state.errorDescriptionOnlySpace) {
    this.setState({
      errorDescriptionOnlySpace: false
    });
  }

  if (value.length > 250) {
    this.setState({
      errorDescriptionLimit: true
    });
  } else {
    if (this.state.errorDescriptionLimit) {
      this.setState({
        errorDescriptionLimit: false
      });
    }
    this.setState({
      surveyDescription: value
    });
  }
}

  handlerAnonymousSurvey = () => {
  this.setState({
    anonymousSurvey: !this.state.anonymousSurvey,
  });
}
validItemEmpty = (item) => {
  let itemValue = this.state[item];
  if (itemValue !== null && itemValue !== undefined && typeof itemValue === 'string') {
    let onlySpace = /^\s*$/.test(itemValue);
    let itemCorrect;
    if(onlySpace){
      itemCorrect = itemValue;
    } else {
      itemCorrect = itemValue.trim();
    }
    this.setState({ [item]: itemCorrect });
    return itemCorrect;
  }

  return itemValue;
}
handlerNextPreview(event){
  let that = this;
  let error = false;
            let start = new Date(this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.startTimeCalendar + ':00');
          let end = new Date(this.state.endDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00');
          let differenceInMilliseconds = end - start;
          let differenceInMinutes = differenceInMilliseconds / 1000 / 60;
  this.setState({
    errorFields: false,
    errorQuestions: false,
    errorNameOnlySpace: false,
                errorDiffDate: false,
            errorDiffMinutesDate: false
  });
  let surveyName = this.validItemEmpty('surveyName');
  if(/^\s*$/.test(surveyName) && surveyName !== ''){
    error = true;
    this.setState({errorNameOnlySpace: true });
  }
  let surveyDescription = this.validItemEmpty('surveyDescription');
  if(/^\s*$/.test(surveyDescription) && surveyDescription !== ''){
    error = true;
    this.setState({errorDescriptionOnlySpace: true });
  }
  if(that.state.surveyBonus !== '' && that.state.surveyBonus < 1){
    error = true;
  }
            if (start > end) {
            error = true;
            this.setState({
              errorDiffDate: true
            });
          }
            if (differenceInMinutes < 15) {
            error = true;
            this.setState({
              errorDiffMinutesDate: true
            });
          }
  if(!error && surveyName && this.state.smileValue && this.state.surveyDescription && this.state.startDateCalendar && this.state.startTimeCalendar && (this.state.getUsersIds.length > 0 || this.state.getAllUsers)){
      let errorQuestions = false;
      this.state.options.forEach((item, i) => {
        if(item.options[0].name === '' || item.options[0].answerOptionType === ''){
          this.setState({
            errorQuestions: true
          });
          errorQuestions = true;
        }
      });
      if(!errorQuestions){
        if(typeof window !== "undefined" && typeof document !== 'undefined') {
          document.body.classList.add('preview');
        }
        this.setState(prevState => ({
          previewStatus: !prevState.previewStatus
        }));
        let obj = {
          "allUsers": that.state.getAllUsers,
          "anonymous": that.state.anonymousSurvey,
          "bonus": that.state.surveyBonus,
          "description": surveyDescription,
          "endTime": this.state.endDateCalendar ? (this.state.endDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00') : (this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.endTimeCalendar + ':00'),
          "questions": that.state.options,
          "resolution": "string",
          "recurring": that.state.recurring,
          "smile": that.state.smileValue,
          "startTime": this.state.startDateCalendar.split('.').reverse().join('-') + ' ' + this.state.startTimeCalendar + ':00',
          "surveyType": "QUICK",
          "title": surveyName,
          "users": that.state.usersIds
        };
const dateStringStart = obj.startTime;
const [datePartStart, timePartStart] = dateStringStart.split(' ');
const [yearStart, monthStart, dayStart] = datePartStart.split('-').map(Number);
const [hoursStart, minutesStart, secondsStart] = timePartStart.split(':').map(Number);

const dateStartTime = new Date(yearStart, monthStart - 1, dayStart, hoursStart, minutesStart, secondsStart);

const dateStringEnd = obj.endTime;
const [datePartEnd, timePartEnd] = dateStringEnd.split(' ');
const [yearEnd, monthEnd, dayEnd] = datePartEnd.split('-').map(Number);
const [hoursEnd, minutesEnd, secondsEnd] = timePartEnd.split(':').map(Number);

const dateEndTime = new Date(yearEnd, monthEnd - 1, dayEnd, hoursEnd, minutesEnd, secondsEnd);


                obj.startTime = new Date(dateStartTime).toISOString();
                obj.endTime = new Date(dateEndTime).toISOString();
        this.setState({
          objForSubmit: obj
        });
      } else {
        this.setState({
          errorQuestions: true
        });
      }
    } else {
      this.setState({
        errorFields: true
      });
    }
}
  handlerSubmitSurvey (event) {
    event.preventDefault();
    let that = this;
        getAuth().currentUser.getIdToken().then(function(idToken){
        const myHeaders = new Headers({
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
        });
        fetch(`${that.props.queryLink}/api/v1/surveys`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(that.state.objForSubmit)
        }).then(res => {
          if (res.status !== 200) {
            res.json().then(function(data) {
              that.setState({
                errors: data.errors,
                isLoadedSubmitAddSurvey: false
              })
            });
          } else {
              that.setState({
                isLoadedSubmitAddSurvey: false,
                successSubmit: true
              });
              if(typeof window !== "undefined" && typeof document !== 'undefined') {
                localStorage.setItem("successSurvey", JSON.stringify({status: that.state.successSubmit, text: that.props.getTranslate('successStatus-Created')}));
              }
          }
        })
        })
      }
  handlerOptionName (index, event) {
    let options = this.state.options;
    options[index].question = event.target.value;
    this.setState(prevState => ({
      options: options
    }));
  }
  handlerOptionType (index, event) {
    let options = this.state.options;
    options[index].options[0].answerOptionType = event.value;
    options[index].options[0].name = event.label;
    this.setState(prevState => ({
      options: options
    }));
  }
  handlerAddDescription (event) {
    event.preventDefault();
    this.setState(prevState => ({
      addDescription: !prevState.addDescription
    }));
  }
  handlerAnswerType (type, event) {
    let obj = this.state.optionsDefault;
    obj[obj.length - 1].answerType = type;
    this.handlerDropdown();
    this.setState(prevState => ({
      answerType: type
    }));
  }
  handlerDefaultQuestionHeadline (event) {
    let obj = this.state.optionsDefault;
    obj[obj.length - 1].question = event.target.value;
    this.setState(prevState => ({
      optionsDefault: obj
    }));
  }
  handlerAddDefaultPoint (event) {
    event.preventDefault();
    let obj = this.state.optionsDefault;
    let options =         {
          "answerOptionID": 0,
          "answerOptionType": "CHECKBOX",
          "name": "",
          "priority": obj[obj.length - 1].options.length,
          "userAnswers": [
            {
              "answer": "string",
              "optionID": 0,
              "userID": 0,
              "userName": "string"
            }
          ]
        };
    let objOptions = [...obj[obj.length - 1].options, options];
    obj[obj.length - 1].options = objOptions;
    this.setState(prevState => ({
      optionsDefault: obj
    }));
  }
  handlerPointDefaultName (index, event) {
    let obj = this.state.optionsDefault;
    let options = obj[obj.length - 1].options;
    options[index].name = event.target.value;
    this.setState(prevState => ({
      optionsDefault: obj
    }));
  }
  removeQuestionNormal (index, event) {
    event.preventDefault();
    this.setState(prevState => ({
      optionsDefault: prevState.optionsDefault.filter((_, i) => i !== index)
    }));
  }
  handlerRemoveDefaultPoint (index, event) {
    event.preventDefault();
    let questions = this.state.optionsDefault;
    let options = questions[questions.length - 1].options;
    options = options.filter((_, i) => i !== index);
    questions[questions.length - 1].options = options;
    this.setState(prevState => ({
      optionsDefault: questions
    }));
  }
  removeQuestionQuick (index, event) {
    event.preventDefault();
    let options = this.state.options;
    if(options.length > 1){
      options = options.filter((_, i) => i !== index);
      options.map((item, index) => {
        item.priority = index
      });
      this.setState(prevState => ({
        options: options
      }));
    }
  }
  handlerGetAllUsers(){
    this.setState(prevState => ({
      getAllUsers: !prevState.getAllUsers,
      getUsersIds: [],
    }));
  }
  handlerToggleUser(index, name, id){
    if(this.state.getAllUsers){
      this.handlerRemoveAllUsers();
    }
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    if(indexArr !== -1){
      arr.splice(indexArr, 1);
    } else {
      arr = [...arr, {id: id, name: name}];
    }
    this.setState(prevState => ({
      getUsersIds: arr
    }));
  }
  handlerRemoveAllUsers(){
    let data = this.state.resultData;
    data.allUsers = false;
    data.users = [];
    this.setState(prevState => ({
      getAllUsers: false,
      allUsers: false
    }));
  }
  handlerRemoveUser(id){
    let arr = this.state.getUsersIds;
    let indexArr = arr.findIndex(x => x.id === id);
    let ids = [];
    arr.splice(indexArr, 1);
    if(arr){
      ids = [...new Set(arr.map(item => item.id))]
    }
    this.setState(prevState => ({
      getUsersIds: arr,
      usersIDs: ids
    }));
  }
  handlerSetUsers(){
      let ids = [];
      if(this.state.getUsersIds){
        ids = [...new Set(this.state.getUsersIds.map(users => users.id))]
      }
      this.setState(prevState => ({
        allUsers: this.state.getAllUsers ? true : false,
        usersIds: ids
      }));
    this.handleModalDisabled();
  }
  handlerSearchUsers(event) {
    let arr = this.state.users;
    let newArr = [];
    this.setState({
      searchValue: event.target.value,
      usersSearch: []
    });
    let that = this;
    arr.map(function(item) {
      item.users.map(function(users) {
        if (users.name.includes(event.target.value)) {
           newArr.push(users);
          that.setState(prevState => ({
            usersSearch: [...prevState.usersSearch, users]
          }));
        }
      })
    });
  }
  chooseDateHandler(choose, event){
    this.setState({
      chooseDateType: choose
    });
  }
  handlerReccuring(event){
    this.setState({
      recurring: event.value
    });
  }
  handlerGetStartDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    let nthWeekday = this.getNthWeekdayOfMonth(new Date(year, (monthValue - 1), day));
    this.setState({
      startDateCalendar: day + '.' + monthValue + '.' + year,
      iterStartCalendar: iter,
      weeklyDay: new Date(year, (monthValue - 1), day).getDay(),
      nthWeekday: nthWeekday - 1,
      dateNum: day,
      monthNum: month
    });
  }

  handlerGetStartTime(time, event){
    event.preventDefault();
    this.setState({
      startTimeCalendar: time
    });
  }

  handlerGetEndDate(iter, day, month, year, next, prev, event){
    event.preventDefault();
    let monthValue = month + 1;
    if(next){
      monthValue = monthValue + 1;
      if(monthValue === 13) {
        monthValue = 1;
      }
    } else if(prev){
      monthValue = monthValue - 1;
      if(monthValue === 0) {
        monthValue = 12;
      }
    } else {
      monthValue = monthValue;
    }
    this.setState({
      endDateCalendar: day + '.' + monthValue + '.' + year,
      iterFinishCalendar: iter
    });
  }

  handlerGetEndTime(time, event){
    event.preventDefault();
    this.setState({
      endTimeCalendar: time
    });
  }

  getNameNumberDay(weekday, num, numberNameOne, numberNameSecond){
    if(weekday == 1 || weekday == 2 || weekday == 4){
      return numberNameOne[num];
    } else {
      return numberNameSecond[num];
    }
  }

  getNthWeekdayOfMonth(date) {
    const year = date.getFullYear();
    const month = date.getMonth();
    const weekday = date.getDay();
    let nthWeekday = 0;

    for (let i = 1; i <= date.getDate(); i++) {
      const currentDate = new Date(year, month, i);
      if (currentDate.getDay() === weekday) {
        nthWeekday++;
        if (currentDate.getTime() === date.getTime()) {
          return nthWeekday;
        }
      }
    }

    return null;
  }

  handlerChangDateInfo(status){
    this.setState(prevState => ({
      changeStatus: status
    }));
  }
  handlerGetEmoji(smile, event){
    this.setState({
      smileValue: smile
    });
  }
  handlerToggleUsersTeam(teamID, event) {
      const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
      const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
      const existingIds = new Set(this.state.getUsersIds.map(user => user.id));
      const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
      let updatedGetUsersIds;
      if (filteredUsers.length > 0) {
          updatedGetUsersIds = this.state.getUsersIds.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
      } else {
          updatedGetUsersIds = [...this.state.getUsersIds, ...newUsers];
      }
      this.setState({ getUsersIds: updatedGetUsersIds });
  }
  handlerHoverEnterUserTeam(teamID, event){
    const teamUsers = this.state.users.find(team => team.teamID === teamID)?.users || [];
    const newUsers = teamUsers.map(user => ({ id: user.userID, name: user.name }));
    const existingIds = new Set(this.state.getUsersIdsHover.map(user => user.id));
    const filteredUsers = newUsers.filter(user => existingIds.has(user.id));
    let updatedGetUsersIds;
    if (filteredUsers.length > 0) {
        updatedGetUsersIds = this.state.getUsersIdsHover.filter(user => !filteredUsers.some(newUser => newUser.id === user.id));
    } else {
        updatedGetUsersIds = [...this.state.getUsersIdsHover, ...newUsers];
    }
    this.setState({ getUsersIdsHover: updatedGetUsersIds });
  }

  handlerHoverLeaveUserTeam(event){
    this.setState({ getUsersIdsHover: [] });
  }

  render() {
        const days = [
      this.props.getTranslate('dayWeek-Sunday'),
      this.props.getTranslate('dayWeek-Monday'),
      this.props.getTranslate('dayWeek-Tuesday'),
      this.props.getTranslate('dayWeek-Wednesday'),
      this.props.getTranslate('dayWeek-Thursday'),
      this.props.getTranslate('dayWeek-Friday'),
      this.props.getTranslate('dayWeek-Saturday')
    ];
    const monthes = [
      this.props.getTranslate('modal-calendarMonthNameJan'),
      this.props.getTranslate('modal-calendarMonthNameFeb'),
      this.props.getTranslate('modal-calendarMonthNameMar'),
      this.props.getTranslate('modal-calendarMonthNameApr'),
      this.props.getTranslate('modal-calendarMonthNameMay'),
      this.props.getTranslate('modal-calendarMonthNameJun'),
      this.props.getTranslate('modal-calendarMonthNameJul'),
      this.props.getTranslate('modal-calendarMonthNameAug'),
      this.props.getTranslate('modal-calendarMonthNameSep'),
      this.props.getTranslate('modal-calendarMonthNameOct'),
      this.props.getTranslate('modal-calendarMonthNameNov'),
      this.props.getTranslate('modal-calendarMonthNameDec')
    ];
    const daysWeekArr = [this.props.getTranslate('dayWeek-Sunday'), this.props.getTranslate('dayWeek-Monday'), this.props.getTranslate('dayWeek-Tuesday'), this.props.getTranslate('dayWeek-Wednesday'), this.props.getTranslate('dayWeek-Thusday'), this.props.getTranslate('dayWeek-Friday'), this.props.getTranslate('dayWeek-Saturday')];
    const numberNameOne = [
      this.props.getTranslate('numberNameOne-First'),
      this.props.getTranslate('numberNameOne-Second'),
      this.props.getTranslate('numberNameOne-Third'),
      this.props.getTranslate('numberNameOne-Fourth'),
      this.props.getTranslate('numberNameOne-Fifth')
    ];
    const numberNameSecond = [
      this.props.getTranslate('numberNameSecond-First'),
      this.props.getTranslate('numberNameSecond-Second'),
      this.props.getTranslate('numberNameSecond-Third'),
      this.props.getTranslate('numberNameSecond-Fourth'),
      this.props.getTranslate('numberNameSecond-Fifth')
    ];
    if(typeof window !== "undefined" && typeof document !== 'undefined') {
      document.title = this.props.getTranslate('polls-AddTitle') + ' | ' + this.props.companyName;
    }
    const choosePoint = [
      { value: 'RATE', label: this.props.getTranslate('polls-QuestionPointLabelRate') },
      { value: 'YES_NO', label: this.props.getTranslate('polls-QuestionPointLabelYesNo') },
      { value: 'YES_NO_DONT_KNOW', label: this.props.getTranslate('polls-QuestionPointLabelYesNoDontKnow') },
      { value: 'FREE', label: this.props.getTranslate('polls-QuestionPointLabelFree') }
    ];
    const repeatChoose = [
      { value: null, label: this.props.getTranslate('events-RepeaNull')},
      { value: 'DAILY', label: this.props.getTranslate('events-RepeatDay') },
      { value: 'WEEKLY', label: this.props.getTranslate('events-RepeatWeek') + ' - ' + this.getNameDay(this.state.weeklyDay, daysWeekArr) },
      { value: 'MONTHLY', label: this.props.getTranslate('events-RepeatMonth') + ' - ' + this.getNameNumberDay(this.state.weeklyDay, this.state.nthWeekday, numberNameOne, numberNameSecond) + ' ' + this.getNameDay(this.state.weeklyDay, daysWeekArr) },
    ];
    let chooseUsers = '';
    if(this.state.usersIds.length > 0 && !this.state.allUsers){
      chooseUsers = this.state.getUsersIds.map((item, index) => {
        return <span className="select-users__label" key={index}>{item.name}{this.state.previewStatus ? '' : <span onClick={this.handlerRemoveUser.bind(this, item.id)}><svg className="select-users__remove">
                                  <use href={`${sprite}#close-icon`}></use>
                                </svg></span>}</span>
      })
    }
    let users = '';
    if(this.state.errorUsers) {
      users = <div>Ошибка...</div>;
    } else if(!this.state.isLoadedUser) {
      users = <Loading />;
    } else {
      users = this.state.searchValue ?       <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        {this.state.usersSearch.map((elem, index) => {
          let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            return <div className={`${active ? 'users-modal__item active' : 'users-modal__item'}`} key={index} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                 <div className="users-modal__info">
                   <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                   <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                 </div>
                 {active ? <div className="users-modal__check-wrap">
                   <svg className="users-modal__check">
                     <use href={`${sprite}#check-icon`}></use>
                   </svg>
                 </div> : ''}
               </div>
        })}
      </div></Scrollbars> : <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
            renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            renderView={props => <div {...props} className="view"/>}
              autoHeight
              autoHeightMin={0}
              autoHeightMax={500}
              ><div className="team-list-modal">
        <div className={`${this.state.getAllUsers ? 'users-modal__item active' : 'users-modal__item'}`} onClick={this.handlerGetAllUsers.bind(this)}>
          <div className="users-modal__info">
            <div className="users-modal__avatar-icon-wrap">
              <svg className="users-modal__avatar-icon">
                <use href={`${sprite}#all-team-icon`}></use>
              </svg>
            </div>
            <span className="users-modal__name">{this.props.getTranslate('modalAddUser-AllUsersLabel')}</span>
          </div>
          {this.state.getAllUsers && <div className="users-modal__check-wrap">
            <svg className="users-modal__check">
              <use href={`${sprite}#check-icon`}></use>
            </svg>
          </div>}
        </div>
        {this.state.users.map((item, index) => {
         return item.users.length > 0 ? <div key={index}>
         <div className="team-list-modal__label" onClick={this.handlerToggleUsersTeam.bind(this, item.teamID)} onMouseEnter={this.handlerHoverEnterUserTeam.bind(this, item.teamID)} onMouseLeave={this.handlerHoverLeaveUserTeam.bind(this)}>{item.name ? item.name : this.props.getTranslate('modalAddUser-NoTeamLabel')}</div>
          {item.users.map((elem, i) => {
            let active = this.state.getUsersIds.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
            let hoverActive = this.state.getUsersIdsHover && this.state.getUsersIdsHover.findIndex(x => x.id === elem.userID) !== -1 ? true : false;
              return <div className={`${this.state.getAllUsers || active || hoverActive ? 'users-modal__item active' : 'users-modal__item'}`} key={i} onClick={this.handlerToggleUser.bind(this, index, elem.name, elem.userID)}>
                   <div className="users-modal__info">
                     <img className="users-modal__avatar" src={elem.photo ? elem.photo : member_1} alt="" />
                     <div className="users-modal__name">{elem.name ? elem.name : ''}</div>
                   </div>
                   {this.state.getAllUsers || active ? <div className="users-modal__check-wrap">
                     <svg className="users-modal__check">
                       <use href={`${sprite}#check-icon`}></use>
                     </svg>
                   </div> : ''}
                 </div>
          })}
        </div> : ''})}
       </div></Scrollbars>;
    }
    let totalItem = this.state.dates.length * 7;
    let totalItemNotPush = totalItem - this.state.pushElemsTotal;
    let firstItems = this.state.countFirstElems;
    let endedItems = totalItem - this.state.countEndedElems;
    let iter = 0;
    let tableDate = this.state.dates.map((item, index) => {
      return <tr key={index}>
        {item.map((elem, i) => {
          iter++;
          if((this.state.date < this.state.currentDate && this.state.date.getMonth() !== this.state.currentDate.getMonth()) || (index === 0 && this.state.countFirstElems > i && this.state.month === this.state.currentDate.getMonth())){
            return <td className="ended" key={i}>{elem}</td>
          } else if(this.state.currentDate >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + (elem + 1)) && iter < endedItems && (this.state.date.getMonth()) <= (this.state.currentDate.getMonth()) && this.state.date.getFullYear() <= this.state.currentDate.getFullYear()){
            return <td className="ended" key={i}>{elem}</td>
          } else if(new Date(this.state.startDate) <= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem) && new Date(this.state.hoverDate) >= new Date(this.state.fullYear + '.' + (this.state.month + 1) + '.' + elem)) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.startDate && (new Date(this.state.startDate).getDate() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getDate()) && (new Date(this.state.startDate).getMonth() - 1 === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getMonth()) && (new Date(this.state.startDate).getFullYear() === new Date(this.state.fullYear + '.' + this.state.month + '.' + elem).getFullYear())) {
            return <td className="checked" key={i} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)} onMouseLeave={this.chooseEndHover.bind(this)}>{elem}</td>
          } else if(this.state.iterStart && (this.state.countMonth >= this.state.countMonthSave && this.state.iterStart <= iter && this.state.iterHover >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && (this.state.countMonth <= this.state.countMonthFinish && this.state.countMonth >= this.state.countMonthSave) && (this.state.iterStart <= iter && this.state.iterFinish >= iter)) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if(this.state.iterStart && this.state.iterFinish && this.state.countMonth < this.state.countMonthFinish && this.state.countMonth > this.state.countMonthSave){
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else if((this.state.iterStart && this.state.iterFinish) && (this.state.countMonthSave !== this.state.countMonthFinish) && (this.state.countMonth === this.state.countMonthSave) && iter > this.state.iterStart) {
            return <td className="checked" key={i} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          } else {
            return <td key={i} onMouseEnter={this.chooseDateHover.bind(this, iter)} onMouseLeave={this.chooseEndHover.bind(this)} onClick={this.chooseDate.bind(this, iter, iter > endedItems ? true : false, iter < firstItems ? true : false)}>{elem}</td>
          }
        })}
      </tr>
    });
    let reccuringForSubmit = '';
    if(this.state.objForSubmit){
      if(this.state.objForSubmit.recurring === 'DAILY'){
        reccuringForSubmit = this.props.getTranslate('events-RepeatDay');
      } else if(this.state.objForSubmit.recurring === 'WEEKLY') {
        reccuringForSubmit = this.props.getTranslate('events-RepeatWeek');
      } else if(this.state.objForSubmit.recurring === 'MONTHLY'){
        reccuringForSubmit = this.props.getTranslate('events-RepeatMonth');
      } else {
        reccuringForSubmit = this.props.getTranslate('events-RepeaNull');
      }
    }
    return <>
    <ResultErrors errors={this.state.errors} closeHandler={this.handlerCloseError.bind(this)} />
    {(typeof window !== "undefined" && typeof document !== 'undefined') ? (this.state.successSubmit && <Navigate to={this.props.getPermalink('/account/surveys/quick')} replace={true} />) : ''}
    <AccountHeader queryLink={this.props.queryLink} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} getTranslate={this.props.getTranslate.bind(this)} completed={this.props.completed} />
    <div className="account-wrap">
      <AccountNav getPermalink={this.props.getPermalink} queryLink={this.props.queryLink} configsApp={this.props.configsApp} newReview={this.props.newReview} companyName={this.props.companyName} token={this.props.token()} status={this.state.mobileMenu} statusStart={this.state.mobileMenuStart} handler={this.handlerMobileMenu} dropdownStatus={this.state.dropdownStatus} dropdownHandler={this.handlerDropdown} getTranslate={this.props.getTranslate} completed={this.props.completed} />
      <div className="account-content">
      {this.state.previewStatus ? <div className="account-content__inner account-preview">

        {/* Account header component */}
        <AccountContentHeader
          handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
          languageActive={this.props.chooseLanguage}
          preview={true}
          backBtn={true}
          backBtnType="btn"
          backBtnHandler={this.handlerNextPreview.bind(this)}
          backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
        />

        <h2 className="account-preview__headline">{this.props.getTranslate('polls-previewTitle')}</h2>
        <div className="account-preview__inner account-preview__inner_preview">
          <div className="account-preview__info">
            <div className="account-preview__wgt">
              <div className="account-preview__wgt_row">
                <div className="account-preview__wgt_col-info-survey">
                  <div className="account-preview__wgt-headline">
                    {this.state.objForSubmit.smile ? <div className="account-preview__wgt-smile" dangerouslySetInnerHTML={{__html: this.state.objForSubmit.smile}}></div> : <img className="account-preview__wgt-smile" src={smile} alt="" />}
                    <div className="account-preview__wgt-headline-text">{this.state.objForSubmit.title}</div>
                  </div>
                  <div className="account-preview__info-row account-preview__info-row_space_between">
                    <div className="account-preview__info-wgt">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('polls-previewDescriptionLabel')}</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.description}</div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('polls-previewDateHoldingLabel')}</div>
                      <div className="account-preview__info-wgt-value"><DateFormating start={this.state.objForSubmit.startTime} end={this.state.objForSubmit.endTime} /></div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('polls-previewReccuringLabel')}</div>
                      <div className="account-preview__info-wgt-value">{reccuringForSubmit}</div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('polls-previewBonuseLabel')}</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.bonus}</div>
                    </div>
                    <div className="account-preview__info-wgt account-preview__info-wgt_width_auto">
                      <div className="account-preview__info-wgt-label">{this.props.getTranslate('polls-previewTypeLabel')}</div>
                      <div className="account-preview__info-wgt-value">{this.state.objForSubmit.anonymous ? this.props.getTranslate('polls-previewTypeAnonim') : this.props.getTranslate('polls-previewTypePublic')}</div>
                    </div>
                    <div className="account-preview__info-wgt">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('polls-Users')}</label>
                      <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`}>
                      {this.state.usersIds.length < 1 ?
                      <div className="select-users__input">
                        {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}</span> : <div className="select-users__placeholder">{this.props.getTranslate('polls-UsersFieldCaption')}</div>}
                      </div> : <div className="select-users__input">{chooseUsers}</div>}
                      </div>
                      {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('polls-UsersFieldCaption')}</div> : ''}
                    </div>
                  </div>
                  {this.state.objForSubmit.questions ? <>
                      {this.state.objForSubmit.questions.map((item, index) => {
                        return <div className="account-preview__survey-wgt" key={index}>
                          <div className="account-preview__survey-wgt-question">
                              {item.options ? <>
                              {item.options.map((option, i) => {
                                let optionType = '';
                                if(option.answerOptionType === 'RATE'){
                                  optionType = this.props.getTranslate('polls-QuestionPointLabelRate');
                                } else if(option.answerOptionType === 'YES_NO' || option.answerOptionType === 'CHECKBOX ') {
                                  optionType = this.props.getTranslate('polls-QuestionPointLabelYesNo');
                                } else if(option.answerOptionType === 'YES_NO_DONT_KNOW') {
                                  optionType = this.props.getTranslate('polls-QuestionPointLabelYesNoDontKnow');
                                } else if(option.answerOptionType === 'FREE') {
                                  optionType = this.props.getTranslate('polls-QuestionPointLabelFree');
                                }
                                return <span className="account-preview__survey-wgt-text" key={i}>{optionType}</span>;
                              })}
                              </> : ''}
                          </div>
                          <div className="account-preview__survey-wgt-question-quick">{item.question}</div>
                        </div>
                      })}</> : ''}
                      <div className="account-preview__info-nav account-preview__info-nav-between">
                        <button className="button-standart button-standart_theme_blue" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('polls-BtnEdit')}</button>
                        <button className="btn-edit btn-edit_theme_remove" type="button" onClick={this.handlerDeleteEventModal.bind(this)}>
                          <svg className="btn-edit__icon">
                            <use href={`${sprite}#delete-red`}></use>
                          </svg>
                        </button>
                      </div>
                </div>
              </div>
            </div>
            <button className="preview-submit-btn" type="button" disabled={this.state.isLoadedSubmitAddSurvey ? true : false} onClick={this.handlerSubmitSurvey.bind(this)}>{this.props.getTranslate('polls-PreviewSubmitPulish')}{this.state.isLoadedSubmitAddSurvey && <Loading />}</button>
          </div>
          <div className="account-preview__img">
            <div className="account-preview__img-description">{this.props.getTranslate('polls-PreviewExample')}</div>
            <img src={img_preview} alt="" />
          </div>
        </div>
      </div> : <div className="account-content__inner">

          {/* Account header component */}
          <AccountContentHeader
            handlerLanguage={this.props.handlerChangeLanguage.bind(this)}
            languageActive={this.props.chooseLanguage}
            backBtn={true}
            backBtnType="link"
            backBtnClass="button-back_theme_gray"
            backBtnHandler={this.props.getPermalink("/account/surveys/quick")}
            backBtnTxt={this.props.getTranslate('buttonBack-buttonText')}
          />

          <div className="account-content__header-title">
            <h1 className="account-content__headline">{this.props.getTranslate('polls-AddTitle')}</h1>
          </div>
          <div className="questions-form-wrap">
            <div className="questions-form">
              {this.state.addQuestion ? <>
                <div className="question-info">
                  <h2 className="question-info__headline">{this.state.surveyName}</h2>
                  <div className="question-info__content">
                    <div className="question-info__wgt">{this.state.chooseAllMembers ? this.state.totalUsers : this.state.chooseMembersIds.length} чел.</div>
                    <div className="question-info__wgt">{this.state.finishStartDate + ' ' + this.state.startTime + ' - ' + this.state.finishEndDate + ' ' + this.state.endTime}</div>
                    <div className="question-info__wgt">{this.state.anonymousSurvey ? this.props.getTranslate('polls-previewTypeAnonim') : this.props.getTranslate('polls-previewTypePublic')}</div>
                  </div>
                </div>
                {this.state.optionsDefault.map((item, index) => {
                  if(index !== this.state.optionsDefault.length - 1) {
                    return <div className="question-wgt-item" key={index}>
                      <div className="question-wgt-item__name">№{index + 1} {this.state.optionsDefault[index].question}</div>
                      <div className="question-wgt-item__nav">
                      <button className="question-wgt-item__nav-btn" type="button" onClick={this.removeQuestionNormal.bind(this, index)}>
                        <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_delete">
                          <use href={`${sprite}#delete`}></use>
                        </svg>
                      </button>
                      <button className="question-wgt-item__nav-btn" type="button">
                        <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
                          <use href={`${sprite}#lines-icon`}></use>
                        </svg>
                      </button>
                      </div>
                    </div>
                  } else {
                    return '';
                  }
                })}
                <div className="question-form">
                  <div className="question-form__header">
                    <div className="question-form__num">№{this.state.optionsDefault.length}</div>
                  </div>
                  <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="question-headline">Заголовок</label>
                    <input id="question-headline" className="field-wrap__input" type="text" value={this.state.optionsDefault[this.state.optionsDefault.length - 1].question} onChange={this.handlerDefaultQuestionHeadline.bind(this)} placeholder="Введите вопрос" />
                  </div>
                  {this.state.addDescription ? <div className="field-wrap">
                    <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="question-description">Описание</label>
                    <textarea id="question-description" className="field-wrap__input field-wrap__input_questions" placeholder="Введите описание" />
                  </div> : ''}
                  <button className="question-form__btn" type="button" onClick={this.handlerAddDescription.bind(this)}>Добавить описание</button>
                  <div className="question-form__points">
                    <div className="question-form__points-header">
                      <div className="question-form__points-header-inner">
                        <label className="field-wrap__label field-wrap__label_theme_gray">Варианты ответов</label>
                        <label className="field-wrap__label field-wrap__label_theme_gray field-wrap__label-questions">Тип списка:</label>
                        <div className="dropdown dropdown__account-sort dropdown__questions">
                          <button className="dropdown__btn" type="button" onClick={this.handlerDropdown.bind(this)}>
                            <div className="dropdown__btn-name">{this.state.answerType === 'SINGLE' ? 'Один из списка' : 'Несколько вариантов'}</div>
                            <svg className="dropdown__icon">
                              <use href={`${sprite}#arrow-down`}></use>
                            </svg>
                          </button>
                          <div className={`dropdown-content ${this.state.dropdownStatus ? 'active' : ''}`}>
                            <ul className="dropdown-nav">
                              <li className="dropdown-nav__item">
                                <button className={`dropdown-nav__link ${this.state.answerType === 'SINGLE' ? 'active' : ''}`} type="button" onClick={this.handlerAnswerType.bind(this, 'SINGLE')}>Один из списка</button>
                              </li>
                              <li className="dropdown-nav__item">
                                <button className={`dropdown-nav__link ${this.state.answerType === 'MULTIPLE' ? 'active' : ''}`} type="button" onClick={this.handlerAnswerType.bind(this, 'MULTIPLE')}>Несколько вариантов</button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {this.state.optionsDefault[this.state.optionsDefault.length - 1].options.map((item, index) => {
                        return               <div className="field-wrap question-form__points-item" key={index}>
                                            {this.state.answerType === 'SINGLE' ?
                                            <div className="question-form__points-item-icon">
                                            <svg className="question-form__points-item-checked-icon">
                                              <use href={`${sprite}#radio-icon`}></use>
                                            </svg>
                                            </div> :
                                            <div className="question-form__points-item-icon">
                                              <svg className="question-form__points-item-checked-icon">
                                                <use href={`${sprite}#checkbox-icon`}></use>
                                              </svg>
                                            </div>}
                                              <input className="field-wrap__input" type="text" value={this.state.optionsDefault[this.state.optionsDefault.length - 1].options[index].name} onChange={this.handlerPointDefaultName.bind(this, index)} />
                                              <div className="question-form__points-item-nav">
                                                <button className="question-wgt-item__nav-btn" type="button" onClick={this.handlerRemoveDefaultPoint.bind(this, index)}>
                                                  <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_close">
                                                    <use href={`${sprite}#close-icon`}></use>
                                                  </svg>
                                                </button>
                                                <button className="question-wgt-item__nav-btn" type="button">
                                                  <svg className="question-wgt-item__nav-icon question-wgt-item__nav-icon_sort">
                                                    <use href={`${sprite}#lines-icon`}></use>
                                                  </svg>
                                                </button>
                                              </div>
                                            </div>
                      })}
                      <button className="question-form__btn" type="button" onClick={this.handlerAddDefaultPoint.bind(this)}>Добавить вариант</button>
                    </div>
                  </div>
                </div>
                </> : <div className="questions-form__inner">
                <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="headline">{this.props.getTranslate('polls-QuestionCaption')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipSurvey-TitleSurvey')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="field-wrap__smile">
                      <div className="field-wrap__smile__inner field-wrap__input_width_75">
                        <input id="headline" className={`field-wrap__input ${(this.state.errorFields && !this.state.surveyName) || (this.state.errorFields && this.state.errorNameOnlySpace && this.state.headlineValue !== '') || this.state.surveyNameLength ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.surveyName} onChange={this.handlerSurveyName.bind(this)} placeholder={this.props.getTranslate('polls-CaptionFieldCaption')} />
                        {this.state.errorFields && !this.state.surveyName ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                        {this.state.errorFields && this.state.errorNameOnlySpace && this.state.surveyName !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                        {this.state.surveyNameLength ? <div className="validation-error">{this.props.getTranslate('validation-SurveyNameLength')}</div> : ''}
                      </div>
                      <GetEmoji
                        tooltipValue={this.props.getTranslate('event-tooltipEmoji')}
                        onChangeHandler={this.handlerGetEmoji.bind(this)}
                        status={this.state.errorFields && !this.state.smileValue ? true : false}
                        smileValue={this.state.smileValue}
                      />
                  </div>
                </div>
                <div className="field-wrap">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="start-description">{this.props.getTranslate('polls-MainTextQuick')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipSurvey-StartDescriptionQuickSurvey')}</p>
                        </div>
                      </div>
                    </div>
                  <textarea id="start-description" className={`field-wrap__input field-wrap__input_questions ${(this.state.errorFields && !this.state.surveyDescription) || (this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.surveyDescription !== '') || this.state.errorDescriptionLimit ? 'field-wrap__input_error' : ''}`} value={this.state.surveyDescription} onChange={this.handlerSurveyDescription.bind(this)} placeholder={this.props.getTranslate('polls-MainTextFieldCaption')} />
                  {this.state.errorFields && !this.state.surveyDescription ? <div className="validation-error">{this.props.getTranslate('validation-RequiredField')}</div> : ''}
                  {this.state.errorFields && this.state.errorDescriptionOnlySpace && this.state.surveyDescription !== '' ? <div className="validation-error">{this.props.getTranslate('validation-OnlySpaces')}</div> : ''}
                  {this.state.errorDescriptionLimit ? <div className="validation-error">{this.props.getTranslate('validation-SurveyDescriptionLength')}</div> : ''}
                </div>
                                  <div className="field-wrap questions-form__repeat">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('polls-Repeat')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('polls-RepeatTooltip')}</p>
                        </div>
                      </div>
                    </div>
                    <Select
                      styles={customStyles}
                      value={repeatChoose ? repeatChoose[repeatChoose.findIndex(el => el.value === this.state.recurring)] : null}
                      options={repeatChoose}
                      onChange={this.handlerReccuring.bind(this)}
                      placeholder={this.props.getTranslate('polls-RepearFieldCaption')}
                    />
                  </div>
                <div className="questions-form__row">
                  <CalendarDropdownOnceTime
                    label={this.props.getTranslate('events-Date')}
                    handlerGetStartDate={this.handlerGetStartDate.bind(this)}
                    handlerGetEndDate={this.handlerGetEndDate.bind(this)}
                    handlerGetStartTime={this.handlerGetStartTime.bind(this)}
                    handlerGetEndTime={this.handlerGetEndTime.bind(this)}
                    startDate={this.state.startDateCalendar}
                    startTime={this.state.startTimeCalendar}
                    endDate={this.state.endDateCalendar}
                    endTime={this.state.endTimeCalendar}
                    iterStartCalendar={this.state.iterStartCalendar}
                    iterFinishCalendar={this.state.iterFinishCalendar}
                    handlerChangeDateForInfo={this.handlerChangDateInfo.bind(this)}
                    offEndDate={!this.state.recurring}
                    fullStartDate={!this.state.recurring}
                    errors={[this.state.errorDiffDate ? this.props.getTranslate('validation-errorDiffDate') : '', this.state.errorDiffMinutesDate && !this.state.errorDiffDate ? this.props.getTranslate('validation-errorDiffMinutesDate') : '']}
                  />
                  <div className="field-wrap questions-form__bonus">
                    <div className="field-wrap__label-wrap">
                      <label className="field-wrap__label field-wrap__label_theme_gray" htmlFor="bonus">{this.props.getTranslate('polls-BonusForSurvey')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipSurvey-BonuseSurvey')}</p>
                        </div>
                      </div>
                    </div>
                    <div className="questions-form__bonus-inner">
                      <input id="bonus" className={`field-wrap__input questions-form__bonus-field ${(this.state.surveyBonus !== '' && this.state.surveyBonus < 1 && this.state.errorFields) || this.state.bonuseValueNumError || this.state.bonuseValueCountError ? 'field-wrap__input_error' : ''}`} type="text" value={this.state.surveyBonus} onChange={this.handlerSurveyBonus.bind(this)} placeholder={this.props.getTranslate('polls-BonusFieldCaption')} />
                      {this.props.currency ? <span className="questions-form__bonus-icon">{this.props.currency}</span> : ''}
                    </div>
                    {this.state.surveyBonus !== '' && this.state.surveyBonus < 1 && this.state.errorFields ? <div className="validation-error">{this.props.getTranslate('validation-errorFieldsCountMustMoreBonuses')}</div> : ''}
                    {this.state.bonuseValueNumError && !this.state.bonuseValueCountError ? <div className="validation-error">{this.props.getTranslate('validation-OnlyNum')}</div> : ''}
                    {!this.state.bonuseValueNumError && this.state.bonuseValueCountError ? <div className="validation-error">{this.props.getTranslate('validation-errorPollsCountBonuse')}</div> : ''}
                  </div>
                </div>
                  <div className="field-wrap field-wrap__tooltip">
                    <div className="unlimited-product"><div><input className="styled-checkbox" id="styled-checkbox-1" type="checkbox" checked={this.state.anonymousSurvey} onChange={this.handlerAnonymousSurvey.bind(this)} /><label htmlFor="styled-checkbox-1"></label></div><span>{this.props.getTranslate('polls-Anonim')}</span></div>
                    <div className="tooltip-info">
                      <span className="tooltip-info__icon">?</span>
                      <div className="tooltip-info__content">
                        <div className="arrow"></div>
                        <p>{this.props.getTranslate('tooltipSurvey-AnonymousSurvey')}</p>
                      </div>
                    </div>
                  </div>
                <div className="field-wrap">
                  <label className="field-wrap__label field-wrap__label_theme_gray">{this.props.getTranslate('polls-Users')}</label>
                  <div className={`select-modal ${this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? 'field-wrap__input_error' : ''}`} onClick={this.handlerAddMembers.bind(this)}>
                  {this.state.getUsersIds.length < 1 ?
                  <div className="select-users__input">
                    {this.state.allUsers && this.state.usersIds.length < 1 ? <span className="select-users__label">{this.props.getTranslate('getEmployees-FieldAllPlaceholder')}<span onClick={this.handlerRemoveAllUsers.bind(this)}><svg className="select-users__remove">
                                              <use href={`${sprite}#close-icon`}></use>
                                            </svg></span></span> : <div className="select-users__placeholder">{this.props.getTranslate('polls-UsersFieldCaption')}</div>}
                  </div> : <div className="select-users__input">{chooseUsers}</div>}
                  </div>
                  {this.state.errorFields && (!this.state.allUsers && this.state.usersIds.length < 1) ? <div className="validation-error">{this.props.getTranslate('polls-UsersFieldCaption')}</div> : ''}
                </div>
              </div>}
              <div className="quick-question-points">
                <div className="quick-question-points__inner">
                  <h2 className="quick-question-points__headline">{this.props.getTranslate('polls-QuickQuestionCaption')}</h2>
                    <div className="quick-question-points__header">
                      <div className="field-wrap__label-wrap field-wrap__label-wrap-list-question">
                        <label className="field-wrap__label field-wrap__label_theme_gray quick-question-points__description">{this.props.getTranslate('polls-QuickQuestionList')}</label>
                        <div className="tooltip-info">
                          <span className="tooltip-info__icon">?</span>
                          <div className="tooltip-info__content">
                            <div className="arrow"></div>
                            <p>{this.props.getTranslate('tooltipSurvey-TitleQuestionQuickName')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="field-wrap__label-wrap field-wrap__label-wrap-type-question">
                      <label className="field-wrap__label field-wrap__label_theme_gray quick-question-points__description">{this.props.getTranslate('polls-QuickAnswerType')}</label>
                      <div className="tooltip-info">
                        <span className="tooltip-info__icon">?</span>
                        <div className="tooltip-info__content">
                          <div className="arrow"></div>
                          <p>{this.props.getTranslate('tooltipSurvey-QuestionQuickType')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SortableList items={this.state.options} onSortEnd={this.onSortEnd} axis={'xy'} handlerOnChange={this.handlerOptionName.bind(this)} handlerOptionType={this.handlerOptionType.bind(this)} removeQuestion={this.removeQuestionQuick.bind(this)} choosePoint={choosePoint} error={this.state.errorFields} getTranslate={this.props.getTranslate.bind(this)} useDragHandle />
                  <div className="quick-question-points__add-btn">
                    <button className="btn-default" onClick={this.handlerAddPoint.bind(this)} type="button">{this.props.getTranslate('polls-addQuestionOption')}</button>
                    {this.state.errorQuestions && <div className="quick-question-points__max-item">{this.props.getTranslate('validation-PollsAddInfoQuestionError')}</div>}
                    {this.state.pointsLength && <div className="quick-question-points__max-item">{this.props.getTranslate('validation-PollsAddMaxQuestionError')}</div>}
                  </div>
                </div>
              </div>
              <div className="questions-form__footer">
                <div className="field-wrap__submit">
                  <button className="btn btn_size_lg" type="button" onClick={this.handlerNextPreview.bind(this)}>{this.props.getTranslate('addPolls-buttonGoPreview')}</button>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
    {this.state.modal && this.state.modalAddMembers ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled` : `two`}>
    <div className="modal-background" onClick={this.handleModalDisabled}></div>
    <div className="modal">
      <div className="modal-header">
        <h2 className="modal-headline">{this.props.getTranslate('modal-addUsersTitle')}</h2>
        <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
          <svg className="close-modal__icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="field-wrap field-wrap__search-members">
        <input className="field-wrap__input" type="text" value={this.state.searchValue} onChange={this.handlerSearchUsers.bind(this)} placeholder={this.props.getTranslate('modal-addUsersSearchPlaceholder')} />
        <svg className="field-wrap__search-icon">
          <use href={`${sprite}#search-icon`}></use>
        </svg>
      </div>
      <div className="question-members">
        <div className="question-members-wgt">
          {users}
        </div>
          <div className="field-wrap__submit">
            <button className="btn btn_size_lg btn_width_100 user-info__nav-btn" type="button" onClick={this.handlerSetUsers.bind(this)}>{this.props.getTranslate('modal-addUsersBtnSubmit')}</button>
          </div>
      </div>
    </div>
  </div> : ''}
  {this.state.dateModal && <div className="date-modal">
    <div className="date-modal__overlay" onClick={this.handlerCloseGetDate.bind(this)}></div>
    <div className="date-modal__content">
      <div className="date-modal__header">
        <div className="date-modal__headline">{this.props.getTranslate('polls-Date')}</div>
        <button className="date-modal__close-modal" type="button" onClick={this.handlerCloseGetDate.bind(this)}>
          <svg className="date-modal__close-modal-icon">
            <use href={`${sprite}#close-icon`}></use>
          </svg>
        </button>
      </div>
      <div className="date-fields date-fields__simple">
        <div className={`date-field ${this.state.chooseDateType === 'start' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'start')}>
          <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishStartDate ? this.state.finishStartDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
        </div>
        <div className={`date-field ${this.state.chooseDateType === 'finish' ? 'date-field__border' : ''}`} onClick={this.chooseDateHandler.bind(this, 'finish')}>
          <input className="date-field__date" type="text" maxLength="10" placeHolder="dd.mm.yyyy" value={this.state.finishEndDate ? this.state.finishEndDate : ''} onKeyPress={this.onKeyPress.bind(this)} />
        </div>
      </div>
      <div id="calendar" className="calendar">
        <div className="calendar__info">
          <button className="calendar__month-nav" type="button" onClick={this.handlerPrevMonth.bind(this)}>
            <svg className="calendar__month-nav-icon">
              <use href={`${sprite}#chevron-left`}></use>
            </svg>
          </button>
          <div className="calendar__month-year">{this.getMonthName(this.state.date.getMonth())}, {this.state.date.getFullYear()}</div>
          <button className="calendar__month-nav" type="button" onClick={this.handlerNextMonth.bind(this)}>
            <svg className="calendar__month-nav-icon">
              <use href={`${sprite}#chevron-right`}></use>
            </svg>
          </button>
        </div>
        <table className="calendar-table">
          <thead className="calendar-table__head">
            <tr>
            <th>{this.props.getTranslate('modal-calendarDayWeekMonday')}</th>
            <th>{this.props.getTranslate('modal-calendarDayWeekTuesday')}</th>
            <th>{this.props.getTranslate('modal-calendarDayWeekWednesday')}</th>
            <th>{this.props.getTranslate('modal-calendarDayWeekThursday')}</th>
            <th>{this.props.getTranslate('modal-calendarDayWeekFriday')}</th>
            <th>{this.props.getTranslate('modal-calendarDayWeekSutarday')}</th>
            <th>{this.props.getTranslate('modal-calendarDayWeekSunday')}</th>
            </tr>
          </thead>
          <tbody className="calendar-table__dates">{tableDate}</tbody>
        </table>
      </div>
      <div className="form-nav__submit">
          <button className="btn btn_width_100 btn_size_lg form-nav__submit" type="button" onClick={this.setDateInput.bind(this)}>{this.props.getTranslate('modal-calendarAddBtn')}</button>
      </div>
    </div>
  </div>}

  {this.state.modalDeleteEvents ? <div id="modal-container" className={this.state.modalDisabled ? `two disabled ${this.state.addClass}` : `two ${this.state.addClass}`}>
  <div className="modal-background" onClick={this.handleModalDisabled}></div>
  <div className="modal">
    <div className="modal-header">
      <h2 className="modal-headline">{this.props.getTranslate('modal-deletePollsTitle')}</h2>
      <button className="close-modal" type="button" onClick={this.handleModalDisabled}>
        <svg className="close-modal__icon">
          <use href={`${sprite}#close-icon`}></use>
        </svg>
      </button>
    </div>
      <div className="delete-category-form__description">{this.props.getTranslate('modal-deletePollsDescription')}</div>
      <div className="field-wrap__submit">
        <Link className="btn btn_size_lg btn_theme_remove btn_width_100 user-info__nav-btn" to={this.props.getPermalink("/account/surveys/quick")}>{this.props.getTranslate('removeModal-ButtonSubmit')}</Link>
      </div>
      </div>
  </div> : ''}

    </>;
  }
}
export default SurveysCreateQuick;
